import {Inject, Injectable, Optional} from '@angular/core';
import {FileConstructor} from '../models/file-constructor';
import {PxFile} from '../models/px-file';
import {ImageFile} from '../models/image-file';
import {AudioFile} from '../models/audio-file';
import {FILE_FACTORY_CONFIG} from '../tokens/file-factory-config';
import {IFileFactoryConfig} from '../models/file-factory-config';
import {WINDOW_TOKEN} from '@px/cdk/window';

@Injectable()
export class FileFactoryService {
  private readonly FILE_EXTENSION_TYPE_MAP = new Map<FileConstructor, Array<string>>([
    [ImageFile, ['.jpg', '.png']],
    [AudioFile, ['.mp3', '.mp4']],
  ]);

  constructor(
    @Inject(WINDOW_TOKEN) private window: typeof globalThis,
    @Inject(FILE_FACTORY_CONFIG) @Optional() private config?: IFileFactoryConfig
  ) {
    for (const [type, extensions] of this.config?.fileExtensionMap?.entries() ?? []) {
      this.FILE_EXTENSION_TYPE_MAP.set(type, extensions);
    }
  }

  private getFileType(file: File): FileConstructor | null {
    const fileExtension = PxFile.getRawFileExtension(file);
    return (
      Array.from(this.FILE_EXTENSION_TYPE_MAP.entries()).find(([, extensions]) =>
        extensions.some(e => e.toLowerCase() === fileExtension.toLowerCase())
      )?.[0] ?? null
    );
  }

  create(file: File, name?: string): PxFile {
    const fileCtr = this.getFileType(file);
    const url = this.window.URL.createObjectURL(file);

    return fileCtr ? new fileCtr(file, url, name) : new PxFile(file, url, name);
  }
}

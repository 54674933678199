export interface ILogoUploadParameters {
  url: string;
  fields: string;
  urlAfterUpload: string;
}

export interface IUploadingParams {
  acl: string;
  bucket: string;
  'X-Amz-Algorithm': string;
  'X-Amz-Credential': string;
  'X-Amz-Date': string;
  'X-Amz-Security-Token': string;
  key: string;
  Policy: string;
  'X-Amz-Signature': string;
}

export class LogoUploadParameters {
  url!: string;
  fields!: IUploadingParams;
  urlAfterUpload!: string;
}

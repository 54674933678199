import {Injectable} from '@angular/core';
import {FileValidatorFunction} from '../models/file-validator-function';
import {PxFile} from '../models/px-file';
import {IManyFilesValidationResult} from '../models/many-files-validation-result';

@Injectable()
export class FileValidatorService {
  validate<T, D = never>(file: PxFile, validators: FileValidatorFunction<T, D>[], payload?: D): T[] | null {
    const validationErrors = validators
      .map(validator => validator(file, payload))
      .filter(validationError => validationError !== null);
    return validationErrors?.length ? (validationErrors as T[]) : null;
  }

  validateMany<T, D = never>(
    files: PxFile[],
    validators: FileValidatorFunction<T, D>[],
    payload?: D
  ): IManyFilesValidationResult<T>[] {
    return files.map(file => {
      return {
        file: file,
        errors: this.validate<T, D>(file, validators, payload) ?? [],
      };
    });
  }
}

import {BreakPoint} from '../enums/breakpoint';

export enum AnimationType {
  FADE_IN = 'fade-in',
  SCALE_IN = 'scale-in',
  SLIDE_DOWN = 'slide-down',
}

export type ThemePresetId = string;

export interface IGridThumbnailSizePreset {
  displayName: string;
}

export interface IImageGridSpacingPreset {
  displayName: string;
  wrapperClassName: string;
}

export interface ITypographyPreset {
  displayName: string;
  order: number;
  wrapperClassName: string;
  fonts: IFonts;
  metaData: ITypographyMeta;
}

export interface IFonts {
  families: string[];
  urls: string[];
}

export interface ITypographyMeta {
  displayCSS: IDisplayCss;
  internal?: boolean;
}

export interface IDisplayCss {
  fontFamily: string;
  fontWeight: number;
  textTransform: string;
}

export interface IColors {
  displayName: string;
  order: number;
  wrapperClassName: string;
  metaData: IColorMeta;
}

export interface IColorMeta {
  displayColor: string;
  internal?: boolean;
}

export interface ICover {
  wrapperClassName: string;
  displayName: string;
  order: number;
  componentName: string;
  componentParams: ICoverComponentParams;
  metaData: ICoverMetaData;
}

export interface ICoverComponentParams {
  [key: string]: string | undefined;
  animationType?: AnimationType;
  mode?: string;
}

export interface ICoverMetaData {
  thumbnailPath: string;
  internal?: boolean;
}

export class ThemeConfig {
  coverPresets: Record<ThemePresetId, ICover>;
  colorPresets: Record<ThemePresetId, IColors>;
  typographyPresets: Record<ThemePresetId, ITypographyPreset>;
  gridThumbnailSizePresets: Record<BreakPoint, IGridThumbnailSizePreset>;
  imageGridSpacingPresets: Record<ThemePresetId, IImageGridSpacingPreset>;
  defaultCoverPreset: string;
  defaultImageGridSpacingPreset: string;
  defaultGridThumbnailSizePreset: BreakPoint;
  defaultTypographyPreset: string;
  defaultColorPreset: string;
}

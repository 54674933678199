import {Visitor} from './visitor';
import {VisitorSubmission} from './visitor-submition';
import {Dayjs} from 'dayjs';

export type UnsubmittedSnapshot = {
  dateUpdated: Dayjs | null;
  dateCreated: Dayjs | null;
  favoritesSnapshot: number[];
};

export interface IVisitorFavorites {
  visitor: Visitor;
  submittedSnapshot: VisitorSubmission | null;
  unsubmittedSnapshot: UnsubmittedSnapshot | null;
}

import {NgModule, Type} from '@angular/core';
import {RouteReuseStrategy, RouterModule, Routes} from '@angular/router';
import {RouteReuseService} from './core/services/route-reuse.service';
import {PsfThemesRoutes} from './core/enums/psf-themes-routes.enum';
import type {GalleryHomeModule} from './gallery-home/gallery-home.module';

const APP_ROUTES: Routes = [
  {
    path: PsfThemesRoutes.MAINTENANCE,
    loadChildren: () => import('./maintenance/maintenance.module').then(m => m.MaintenanceModule),
  },
  {
    path: PsfThemesRoutes.UNSUPPORTED_BROWSER,
    loadChildren: () =>
      import('./unsupported-browser/unsupported-browser.module').then(m => m.UnsupportedBrowserModule),
  },
  {
    path: PsfThemesRoutes.AUTH,
    loadChildren: () => import('@px/photographer-auth/feature-auth').then(m => m.PhotographerAuthFeatureAuthModule),
    data: {
      fallbackUrl: `/${PsfThemesRoutes.NOT_FOUND}`,
    },
  },
  {
    path: PsfThemesRoutes.HOME,
    loadChildren: async (): Promise<Type<GalleryHomeModule>> => {
      const m = await import('./gallery-home/gallery-home.module');
      return m.GalleryHomeModule;
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(APP_ROUTES, {paramsInheritanceStrategy: 'always', enableTracing: false})],
  exports: [RouterModule],
  providers: [{provide: RouteReuseStrategy, useClass: RouteReuseService}],
})
export class AppRoutingModule {}

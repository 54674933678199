import {CollectionArchiveResolution} from '../enums/collection-archive-resolution.enum';

export interface IDownloadBasePayload {
  collectionId: string;
  size: CollectionArchiveResolution;
  downloadPin?: string;
}

export interface IDownloadImagePayload extends IDownloadBasePayload {
  imageId: number;
}

export interface IDownloadFavoritesPayload extends IDownloadBasePayload {
  imageIds: number[];
}

export interface IDownloadGalleryPayload extends IDownloadBasePayload {
  email: string;
}

export type IDownloadPinValidationPayload = Required<IDownloadBasePayload>;

import {CollectionArchiveResolution} from '../enums/collection-archive-resolution.enum';
import {ProductFamily} from '@px/shared/data-access/product-product-family';

export interface ICollectionArchivePayload<T> {
  imageCollectionId: string;
  productFamily: ProductFamily;
  resolution: CollectionArchiveResolution;
  context: T;
}

export interface IImageDownloadPayload<T> extends ICollectionArchivePayload<T> {
  imageId: number;
}

export interface ICollectionPinValidationPayload {
  imageCollectionId: string;
  downloadPin: string;
}

<div class="px-theming-typography-wrapper {{ typographyClass }}">
  <div
    class="px-theming-colors-ui-style-wrapper mat-typography mat-app-background {{ colorsClass }} {{ uiStyleClass }}"
    [class.px-theming-wrapper-debug]="debugMode"
  >
    <div class="bootstrap-wrapper">
      <ng-content></ng-content>
    </div>
  </div>
</div>

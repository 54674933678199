import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {PuiButtonCore, PuiButtonCoreComponent} from '@pui/components/button';

@Component({
  selector: 'gds-button',
  standalone: true,
  imports: [PuiButtonCoreComponent],
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  host: {
    '[class.full-width]': 'fullWidth',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GdsButtonComponent extends PuiButtonCore {
  @Input() size: 'small' | 'medium' | 'large' = 'medium';
  @Input() variant: 'solid' | 'outline' | 'naked' | 'square' | 'input' | 'circle' = 'solid';
}

import {Type} from 'class-transformer';
import {IVisitorSubmission, VisitorSubmission} from './visitor-submition';
import {IVisitor, Visitor} from './visitor';
import {FavoriteImageResponse, IFavoriteImageResponse} from './favorite-image-response';

export interface IVisitorSubmissionsResponse {
  submissions: IVisitorSubmission[];
  visitor: IVisitor;

  favorites: IFavoriteImageResponse[];
}

export class VisitorSubmissionsResponse {
  @Type(() => VisitorSubmission)
  submissions!: VisitorSubmission[];

  @Type(() => Visitor)
  visitor!: Visitor;

  @Type(() => FavoriteImageResponse)
  favorites!: FavoriteImageResponse[];
}

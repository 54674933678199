import {ComponentPortal} from '@angular/cdk/portal';
import {Observable} from 'rxjs';

export interface IUiSnackbarContainer<C> {
  open$: Observable<void>;
  closeStart$: Observable<void>;
  closeEnd$: Observable<void>;

  startExit(): void;
  attachPortal(portal: ComponentPortal<C>): void;
}

import {OverlayContainer} from '@angular/cdk/overlay';
import {AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {PlatformEnvironment} from '@px/shared/env';
import {ThemingColorsService} from '../services/theming-colors.service';
import {ThemingTypographyService} from '../services/theming-typography.service';

const LOADING_WRAPPER_CLASS = 'px-theming-wrapper-loading';

@UntilDestroy()
@Component({
  selector: 'px-theme-root',
  standalone: true,
  templateUrl: './theme-root.component.html',
  styleUrls: ['./theme-root.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemeRootComponent implements OnInit, AfterViewInit {
  private readonly colorsService = inject(ThemingColorsService);
  private readonly platform = inject(PlatformEnvironment);
  private readonly overlayContainer = inject(OverlayContainer);
  private readonly typographyService = inject(ThemingTypographyService);
  private readonly cdr = inject(ChangeDetectorRef);

  uiStyleClass = LOADING_WRAPPER_CLASS;
  colorsClass = LOADING_WRAPPER_CLASS;
  typographyClass?: string;
  matOverlayContainerEl?: HTMLElement;

  get debugMode(): boolean {
    return this.platform.DEBUG;
  }

  onColorsChange(): void {
    this.colorsClass = this.colorsService.getActiveWrapperClassName();
    this.updateMatOverlayContainerClasses();
  }

  onTypographyChange(): void {
    this.typographyClass = this.typographyService.getActiveWrapperClassName();
    this.updateMatOverlayContainerClasses();
  }

  updateMatOverlayContainerClasses(): void {
    if (!this.matOverlayContainerEl) {
      return;
    }
    this.matOverlayContainerEl.className =
      'cdk-overlay-container ' + this.typographyClass + ' ' + this.colorsClass + ' ' + this.uiStyleClass;
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    this.uiStyleClass = 'ui-style-artsy';
    this.matOverlayContainerEl = this.overlayContainer.getContainerElement();
    this.colorsService.activePresetId$.pipe(untilDestroyed(this)).subscribe(() => this.onColorsChange());
    this.typographyService.activePresetId$.pipe(untilDestroyed(this)).subscribe(() => this.onTypographyChange());
  }

  ngAfterViewInit(): void {
    this.updateMatOverlayContainerClasses();
  }
}

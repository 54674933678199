// TODO: Will be updated after API implementation

import {Type} from 'class-transformer';

export interface IContactInfo {
  isAllRightReservedPresent: boolean;
  isRightPlaceholderEnabled: boolean;
  isVCardAttachedToEmail: boolean;
  isVCardEnabled: boolean;
  address: string;
  address2: string;
  avatarUrl: string;
  companyName: string;
  copyRightPlaceholder: string;
  country: string;
  email: IContactInfoMultiEntry[];
  firstName: string;
  jobTitle: string;
  lastName: string;
  note: string;
  phone: IContactInfoMultiEntry[];
  postalCode: string;
  state: string;
  website: IContactInfoMultiEntry[];
}

export interface IContactInfoMultiEntry {
  label: string;
  value: string;
}

export class ContactInfoEntry implements IContactInfoMultiEntry {
  label!: string;
  value!: string;

  getCopy(): IContactInfoMultiEntry {
    return {
      label: this.label,
      value: this.value,
    };
  }

  getFixedLabel(): string {
    return this.label.replace(/\W/gi, '');
  }
}

export class ContactInfo implements IContactInfo {
  isAllRightReservedPresent!: boolean;
  isRightPlaceholderEnabled!: boolean;
  isVCardAttachedToEmail!: boolean;
  isVCardEnabled!: boolean;
  address!: string;
  address2!: string;
  avatarUrl!: string;
  companyName!: string;
  copyRightPlaceholder!: string;
  country!: string;

  @Type(() => ContactInfoEntry)
  email!: ContactInfoEntry[];
  firstName!: string;
  jobTitle!: string;
  lastName!: string;
  note!: string;

  @Type(() => ContactInfoEntry)
  phone!: ContactInfoEntry[];
  postalCode!: string;
  state!: string;

  @Type(() => ContactInfoEntry)
  website!: ContactInfoEntry[];

  getFullAddress(): string {
    return (this.address ?? '').concat(
      `${this.address?.length && this.address2?.length ? ', ' : ''} ${this.address2 ?? ''}`
    );
  }
}

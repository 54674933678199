<div
  *ngIf="isVisible"
  class="banner"
  [class.banner--warning]="type === 'warning'"
  [class.banner--info]="type === 'info'"
  [class.banner--primary]="type === 'primary'"
  [class.banner--transparent]="type === 'transparent'"
  [class.banner--center]="isCentered"
  @mdsSlideHeight
>
  <div class="banner__inner">
    <div class="banner__message message">
      <ng-content select="message"></ng-content>
      <div class="message__prefix">
        <ng-content select="prefix"></ng-content>
      </div>
      <div class="message__text">
        <ng-content select="text"></ng-content>
      </div>
      <div class="message__actions">
        <ng-content select="actions"></ng-content>
      </div>
    </div>
    <div class="banner__actions">
      <ng-content select="action"></ng-content>
      <div class="banner__actions banner__actions--close">
        <ng-content select="close"></ng-content>
      </div>
    </div>
  </div>
</div>

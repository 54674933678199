import {ClassProvider, inject, Injectable} from '@angular/core';
import {
  HTTP_INTERCEPTORS,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpStatusCode,
} from '@angular/common/http';
import {Observable, tap} from 'rxjs';
import {SessionProviderFacade} from '@px/shared/session-provider';

@Injectable()
export class NoAuthInterceptor implements HttpInterceptor {
  private readonly sessionProviderFacade = inject(SessionProviderFacade);

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(res => {
        if (res instanceof HttpResponse) {
          if (res.status === HttpStatusCode.Unauthorized || res.status === HttpStatusCode.Forbidden) {
            const session = this.sessionProviderFacade.getSessionService();
            session?.handleNoSession().subscribe();
          }
        }
      })
    );
  }
}

export const NO_AUTH_INTERCEPTOR: ClassProvider = {
  provide: HTTP_INTERCEPTORS,
  useClass: NoAuthInterceptor,
  multi: true,
};

import {ProductFamily} from '@px/shared/data-access/product-product-family';

export interface IFavoriteImagePayload {
  imageCollectionId: string;
  imageId: number;
  state: boolean;
  productFamily: ProductFamily;
  favoritesActivityUrl: string;
  clientEmailTimeoutOverride?: number;
}

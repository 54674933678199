export enum PsfThemesRoutes {
  UI_KIT = 'ui-kit',
  TESTING_GALLERY_PREVIEW = 'testing/gallery-preview',
  MAINTENANCE = 'maintenance',
  UNSUPPORTED_BROWSER = 'unsupported-browser',
  AUTH = 'auth',
  HOME = '',
  NOT_FOUND = '404',
  OPT_OUT = 'opt-out',
  HIDDEN_PHOTOS = 'hidden-photos',
  PHOTOGRAPHER = 'photographer',
  FAVORITES = 'favorites',
  SECTION = 's',
  PEOPLE = 'people',
}

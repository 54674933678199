import {Injectable} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {ThemingEntityBase} from './theming-entity-base.service';

@UntilDestroy()
@Injectable()
export class ThemingColorsService extends ThemingEntityBase {
  private onActivePresetChange(): void {
    // Set the body background color (fixes the issue with the white side bars on iPhone X in landscape):
    this.setActivePresetBodyClass();
  }

  private setActivePresetBodyClass(): void {
    // Remove the existing classes relating to the color scheme:
    const removeClassNames: string[] = [];
    this.document.body.classList.forEach(className => {
      if (className.startsWith('colors-')) {
        removeClassNames.push(className);
      }
    });
    if (removeClassNames.length > 0) {
      this.document.body.classList.remove(...removeClassNames);
    }

    // Add the active class:
    if (this.getActiveWrapperClassName()) {
      this.document.body.classList.add(this.getActiveWrapperClassName() + '-bg');
    }

    // Add color-scheme properties:
    this.document.documentElement.style.removeProperty('color-scheme');
    if (this.getActiveWrapperClassName() === 'colors-dark') {
      this.document.documentElement.style.setProperty('color-scheme', 'dark');
    }
  }

  protected initDefaultValues(): void {
    this.defaultPresetID = null;
    this.presets = {};

    this.activePresetId$.pipe(untilDestroyed(this)).subscribe(this.onActivePresetChange.bind(this));
  }
}

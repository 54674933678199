import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {PSFThemesPlatformEnvironment} from '@px/psf-themes/platform-env';
import {BowserService} from '../services/bowser.service';

@Injectable({
  providedIn: 'root',
})
export class UnsupportedBrowserGuard {
  private readonly bowser = inject(BowserService);
  private readonly router = inject(Router);
  private readonly platform = inject(PSFThemesPlatformEnvironment);

  private skipGuard = false;

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    if (this.platform.hasBrowserSupportFeature() && this.skipGuard) {
      return true;
    }

    if (!this.bowser.satisfies(this.platform.LATEST_BROWSERS_VER)) {
      this.router.getCurrentNavigation().extras.skipLocationChange = true;

      return this.router.createUrlTree(['/unsupported-browser'], {
        queryParams: {
          referer: state.url,
        },
      });
    }

    return true;
  }

  setOverride(): void {
    this.skipGuard = true;
  }
}

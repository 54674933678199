import {Injectable, InjectionToken} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

export const IS_MAINTENANCE_MODE = new InjectionToken<Observable<boolean>>('IS_MAINTENANCE_MODE');

@Injectable({
  providedIn: 'root',
})
export class MaintenanceService {
  get isMaintenanceMode$(): Observable<boolean> {
    return this.maintenance$.asObservable();
  }

  private maintenance$ = new BehaviorSubject(false);

  set setMaintenanceMode(state: boolean) {
    this.maintenance$.next(state);
  }
}

<div class="c-snackbar-wrapper">
  <div
    class="c-snackbar-wrapper__inner"
    [style.transformOrigin]="transformOrigin"
    [@dialogContainer]="animationState"
    (@dialogContainer.start)="onAnimationStart($event)"
    (@dialogContainer.done)="onAnimationDone($event)"
  >
    <ng-template cdkPortalOutlet></ng-template>
  </div>
</div>

import {inject, Injectable} from '@angular/core';
import {ActivatedRoute, Params} from '@angular/router';
import {filter, map, Observable} from 'rxjs';
import {WINDOW_TOKEN} from '@px/cdk/window';
import {IStateParameter} from '../interfaces/state-parameter.interface';

@Injectable({providedIn: 'root'})
export class CommunicationProtocolService {
  private readonly route = inject(ActivatedRoute);
  private readonly window = inject(WINDOW_TOKEN);

  parseNativeUrl<T = Record<string, string>, K = string>(): IStateParameter<T, K> | string {
    const urlSearchParams = new URLSearchParams(this.window.location.search);

    const state = urlSearchParams.get('state');

    return this.parseState<T, K>(state ?? '');
  }

  parseState<T = Record<string, string>, K = string>(state: string): IStateParameter<T, K> | string {
    try {
      const decodedPayload = this.window?.atob(state);

      return JSON.parse(decodedPayload) as IStateParameter<T, K>;
    } catch (e) {
      console.warn(e);
      return state;
    }
  }

  watchStateChanges<T = Record<string, string>, K = string>(): Observable<IStateParameter<T, K>> {
    return this.route.queryParams.pipe(
      filter((params: Params) => params['state']),
      map((params: Params) => this.parseState<T, K>(params['state'])),
      filter((state: IStateParameter<T, K> | string): state is IStateParameter<T, K> => typeof state !== 'string')
    );
  }

  watchAction<T = Record<string, string>, K = string>(name: K): Observable<IStateParameter<T, K>> {
    return this.watchStateChanges<T, K>().pipe(filter(state => state.action === name));
  }

  buildState<T = Record<string, string>, K = string>(state: IStateParameter<T, K>): string {
    return this.window?.btoa(JSON.stringify(state));
  }
}

export interface IProfile {
  firstName: string;
  lastName: string;
  metaData: Record<string, unknown>;
  studioName: string;
  websiteUrl: string;
}

export class Profile implements IProfile {
  firstName!: string;
  lastName!: string;
  metaData!: Record<string, unknown>;
  studioName!: string;
  websiteUrl!: string;
}

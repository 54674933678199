export class CollectionPlatformEnv {
  API_PERSIST!: boolean;
  API_PERSIST_TTL!: number;
  SA_GRAPH_QL_ENDPOINT!: string;
  IMAGES_PATH!: string;
  CLOUD_PROOFING_API_URL!: string;
  SA_CREATE_ALBUM_REFERER!: string;
  SA_OPEN_ALBUM_REFERER!: string;
  PX_OAUTH_CLIENT_ID!: string;
  LEGACY_API_URL!: string;
}

import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy} from '@angular/router';
import {PSFThemesPlatformEnvironment} from '@px/psf-themes/platform-env';

@Injectable({
  providedIn: 'root',
})
export class RouteReuseService implements RouteReuseStrategy {
  private storedRoutes = new Map<string, DetachedRouteHandle>();

  constructor(private platform: PSFThemesPlatformEnvironment) {}

  //should store in cache?
  shouldDetach(): boolean {
    return false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    this.storedRoutes.set(route.routeConfig.path, handle);
  }

  //should get from cash?
  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return !!this.storedRoutes.get(route.routeConfig.path);
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    return this.storedRoutes.get(route.routeConfig.path);
  }
  //should use curr component? If not call shouldDetach
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    return (
      (curr.component === future.component && curr.params.imageId === future.params.imageId) ||
      future.routeConfig === curr.routeConfig
    );
  }
}

import {NgModule} from '@angular/core';
import {Route, RouterModule} from '@angular/router';
import {CodeComponent} from './components/code/code.component';
import {AuthGuard} from './guards/auth.guard';
import {PhotographerAuthDomainModule} from '@px/photographer-auth/domain';
import {RouterHelperModule} from '@px/shared/data-access/router-helper';

export const photographerAuthFeatureAuthRoutes: Route[] = [
  {
    path: 'code',
    component: CodeComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(photographerAuthFeatureAuthRoutes), PhotographerAuthDomainModule, RouterHelperModule],
  declarations: [CodeComponent],
  providers: [AuthGuard],
  exports: [RouterModule],
})
export class PhotographerAuthFeatureAuthModule {}

import {Type} from 'class-transformer';
import {ISocialLinks, SocialLinks} from './social-links.interface';
import {ContactInfo, IContactInfo} from './contact-info.interface';
import vCard from 'vcards-js';
import {Observable, of} from 'rxjs';

export interface IPublicAccount {
  pxId: number;
  socialLinks: ISocialLinks;
  contactInfo: IContactInfo | null;
}

export class PublicAccount implements IPublicAccount {
  private vCard = vCard();

  pxId!: number;
  @Type(() => SocialLinks)
  socialLinks!: SocialLinks;

  @Type(() => ContactInfo)
  contactInfo!: ContactInfo | null;

  getVCardUrl(): string {
    if (!this.contactInfo) {
      return '';
    }

    this.vCard.firstName = this.contactInfo.firstName;
    this.vCard.lastName = this.contactInfo.lastName;
    this.vCard.organization = this.contactInfo.companyName;
    this.vCard.title = this.contactInfo.jobTitle;
    this.vCard.workPhone = this.contactInfo.phone.map(entry => entry.value);
    this.vCard.socialUrls = this.contactInfo.website
      .filter(item => !!item.value)
      .reduce((acc, entry) => ({...acc, [entry.getFixedLabel() || 'custom']: entry.value}), {
        facebook: this.socialLinks.facebook,
        instagram: this.socialLinks.instagram,
        pinterest: this.socialLinks.pinterest,
        tiktok: this.socialLinks.tiktok,
        twitter: this.socialLinks.twitter,
      }) as unknown as {
      facebook: string;
      linkedIn: string;
      twitter: string;
      flickr: string;

      [custom: string]: string;
    };
    this.vCard.email = this.contactInfo.email.map(entry => entry.value);
    this.vCard.workAddress.label = 'work';
    this.vCard.workAddress.street = this.contactInfo.getFullAddress();
    this.vCard.workAddress.stateProvince = this.contactInfo.state;
    this.vCard.workAddress.countryRegion = this.contactInfo.country;
    this.vCard.workAddress.postalCode = this.contactInfo.postalCode;
    this.vCard.note = this.contactInfo.note;
    this.vCard.isOrganization = !!this.contactInfo.companyName;
    let vCardString = this.vCard.getFormattedString().replace(/SOCIALPROFILE;CHARSET=UTF-8;/gm, 'SOCIALPROFILE;');

    this.contactInfo.email
      .filter(item => item.getFixedLabel())
      .forEach(item => {
        vCardString = vCardString.replace(
          `EMAIL;CHARSET=UTF-8;type=HOME,INTERNET:${item.value}`,
          `EMAIL;CHARSET=UTF-8;type=${item.getFixedLabel()},INTERNET:${item.value}`
        );
      });

    this.contactInfo.phone
      .filter(item => item.getFixedLabel())
      .forEach(item => {
        vCardString = vCardString.replace(
          `TEL;TYPE=WORK,VOICE:${item.value}`,
          `TEL;TYPE=${item.getFixedLabel()},VOICE:${item.value}`
        );
      });

    return URL.createObjectURL(new Blob([vCardString]));
  }

  setBase64Avatar(): Observable<boolean> {
    if (!this.contactInfo?.avatarUrl) {
      return of(true);
    }

    const canvas = document.createElement('canvas');
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.src = this.contactInfo?.avatarUrl || '';

    return new Observable<boolean>(observer => {
      img.onload = (): void => {
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(img, 0, 0);
        const dataURL = canvas?.toDataURL();
        const base64Avatar = dataURL.replace('data:image/png;base64,', '');
        this.vCard.logo.embedFromString(base64Avatar, 'image/png');
        this.vCard.photo.embedFromString(base64Avatar, 'image/png');
        observer.next(true);
      };
      img.onerror = (): void => {
        observer.next(true);
      };
    });
  }
}

import {PxFile} from './px-file';

export interface IStreamUploadResult<T> {
  progress: number;
  success: ISStreamSuccessUpload<T>[];
  failed: IStreamFailedUpload[];
  done: boolean;
}

export interface ISStreamSuccessUpload<T> {
  file: PxFile;
  response: T | null;
}

export interface IStreamFailedUpload {
  file: PxFile;
  error: unknown;
}

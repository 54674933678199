import {animate, AUTO_STYLE, state, style, transition, trigger} from '@angular/animations';
import {DURATION, TRANSITION, TRANSITION_IN, TRANSITION_OUT} from '../tokens';

export const mdsSlide = trigger('mdsSlide', [
  state('false', style({maxHeight: '0', opacity: '0', overflow: 'hidden'})),
  state('true', style({maxHeight: '{{maxHeight}}', opacity: '1', overflow: 'hidden'}), {
    params: {maxHeight: '*'},
  }),
  transition('false => true', [animate(TRANSITION)], DURATION),
  transition('true => false', [animate(TRANSITION)], DURATION),
]);

export const puiSlideAnim = trigger('puiSlideAnim', [
  transition(
    ':enter',
    [
      style({height: 0, visibility: 'hidden', opacity: 0}),
      animate(TRANSITION, style({height: AUTO_STYLE, visibility: AUTO_STYLE, opacity: AUTO_STYLE})),
    ],
    DURATION
  ),
  transition(':leave', [animate(TRANSITION_OUT, style({height: 0, visibility: 'hidden', opacity: 0}))], DURATION),
]);

export const puiSlideWidthAnim = trigger('puiSlideWidthAnim', [
  transition(
    ':enter',
    [
      style({width: 0, visibility: 'hidden', opacity: 0, overflow: 'hidden'}),
      animate(TRANSITION, style({width: AUTO_STYLE, visibility: AUTO_STYLE, opacity: AUTO_STYLE})),
    ],
    DURATION
  ),
  transition(
    ':leave',
    [style({overflow: 'hidden'}), animate(TRANSITION_OUT, style({width: 0, visibility: 'hidden', opacity: 0}))],
    DURATION
  ),
]);

export const puiSlideXAnim = trigger('puiSlideXAnim', [
  transition(
    ':enter',
    [
      style({opacity: 0, visibility: 'hidden', transform: 'translateX(100%)'}),
      animate(TRANSITION_IN, style({visibility: 'visible', opacity: 1, transform: 'translateX(0)'})),
    ],
    DURATION
  ),
  transition(
    ':leave',
    [
      style({position: 'absolute'}),
      animate(TRANSITION_OUT, style({opacity: 0, visibility: 'hidden', transform: 'translateX(100%)'})),
    ],
    DURATION
  ),
]);

import {inject, Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {gql} from '@apollo/client';
import {map, Observable} from 'rxjs';
import {
  IExperimentalKeyValue,
  IGetExperimentalKeyValueInput,
  ISetExperimentalKeyValueInput,
} from './interfaces/key-val.interface';
import {PlatformEnvironment} from '@px/shared/env';

@Injectable()
export class ExperimentalKeyValueStorageService {
  private readonly apollo = inject(Apollo);
  private readonly platform = inject(PlatformEnvironment);

  private readonly GET_EXPERIMENTAL_KEY_VALUE_QUERY = gql`
    query GetExperimentalKeyValue($input: GetExperimentalKeyValueInput!) {
      experimentalKeyValue(input: $input) {
        key
        value
      }
    }
  `;

  private readonly SET_EXPERIMENTAL_KEY_VALUE_MUTATION = gql`
    mutation SetExperimentalKeyValue($input: SetExperimentalKeyValueInput!) {
      setExperimentalKeyValue(input: $input) {
        key
        value
      }
    }
  `;

  private buildKey(key: string): string {
    return `exk:${this.platform.DEPLOY_ENV}:${this.platform.PROJECT_GROUP}:${key}`;
  }

  getExperimentalKeyValue(input: IGetExperimentalKeyValueInput): Observable<IExperimentalKeyValue | null> {
    return this.apollo
      .query<{experimentalKeyValue: IExperimentalKeyValue}>({
        query: this.GET_EXPERIMENTAL_KEY_VALUE_QUERY,
        variables: {
          input: {
            pxid: input.pxid,
            key: this.buildKey(input.key),
          },
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map(({data}) => data?.experimentalKeyValue));
  }

  setExperimentalKeyValue(input: ISetExperimentalKeyValueInput): Observable<void> {
    return this.apollo
      .mutate<{setExperimentalKeyValue: IExperimentalKeyValue}>({
        mutation: this.SET_EXPERIMENTAL_KEY_VALUE_MUTATION,
        variables: {
          input: {
            key: this.buildKey(input.key),
            ttlSeconds: input.ttlSeconds,
            value: input.value,
          },
        },
        fetchPolicy: 'network-only',
      })
      .pipe(map(() => undefined));
  }

  invalidateCache(): void {
    this.apollo.client.cache.evict({id: 'ROOT_QUERY', fieldName: 'experimentalKeyValue'});
    this.apollo.client.cache.gc();
  }
}

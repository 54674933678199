export class PxFile {
  get size(): number {
    return this.rawFile.size;
  }
  get name(): string {
    return this.rawFile.name;
  }
  get extension(): string {
    return PxFile.getRawFileExtension(this.rawFile);
  }
  get url(): string {
    return this.urlInstance;
  }
  get type(): string {
    return this.rawFile.type;
  }

  constructor(
    readonly rawFile: File,
    private readonly urlInstance: string,
    readonly fullPath: string | null = null
  ) {}

  static getRawFileExtension(rawFile: File): string {
    return rawFile.name.substring(rawFile.name.lastIndexOf('.'));
  }

  getRawFile(): File & {fullPath: string | null} {
    return Object.assign(this.rawFile, {fullPath: this.fullPath});
  }

  getFolderName(): string {
    const pathParts: string[] = (this.fullPath ?? '').split('/');

    if (pathParts.length < 2) {
      return '';
    }

    return pathParts[pathParts.length - 2];
  }

  async getDimensions(): Promise<{width: number; height: number}> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = (): void => {
        resolve({width: img.width, height: img.height});
        URL.revokeObjectURL(img.src);
      };

      img.onerror = reject;
      img.src = URL.createObjectURL(this.rawFile);
    });
  }
}

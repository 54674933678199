import {Injectable} from '@angular/core';
import {ApolloError} from '@apollo/client';
import {GraphqlErrorType} from '../models/graphql-error-type.enum';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable()
export class GraphqlErrorIdentifierService {
  /**
   * maps apollo errors to our defined errors. Checks network error status and error fields because backend legacy.
   */
  getErrorType(error: ApolloError): GraphqlErrorType {
    return (error.networkError as HttpErrorResponse)?.status === 401 ||
      error.message.toLowerCase().includes('you are not authorized')
      ? GraphqlErrorType.UNAUTHORIZED
      : (error.networkError as HttpErrorResponse)?.status === 403 || error.message.toLowerCase().includes('forbidden')
        ? GraphqlErrorType.FORBIDDEN
        : GraphqlErrorType.UNKNOWN_ERROR;
  }
}

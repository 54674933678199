import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UnsupportedBrowserComponent} from './unsupported-browser/unsupported-browser.component';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {RouterModule, Routes} from '@angular/router';
import {MdsButtonComponent} from '@pui/components/button';
import {MdsIconComponent} from '@pui/components/icon-core';

const routes: Routes = [
  {
    path: '**',
    component: UnsupportedBrowserComponent,
  },
];

@NgModule({
  declarations: [UnsupportedBrowserComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    MatIconModule,
    MdsButtonComponent,
    MdsIconComponent,
  ],
})
export class UnsupportedBrowserModule {}

import {GridSize} from '../enums/grid-size.enum';

export const GRID_SIZE_VARIANTS = {
  [GridSize.md]: {
    columns: 2,
    breakAt: {
      768: {
        columns: 3,
      },
      1280: {
        columns: 5,
      },
      2560: {
        columns: 6,
      },
    },
  },
  [GridSize.lg]: {
    columns: 2,
    breakAt: {
      1280: {
        columns: 4,
      },
    },
  },
  [GridSize.xl]: {
    columns: 1,
    breakAt: {
      768: {
        columns: 2,
      },
      1280: {
        columns: 3,
      },
    },
  },
};

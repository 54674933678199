import {Transform} from 'class-transformer';
import dayjs, {Dayjs} from 'dayjs';

export interface IVisitorSubmission {
  dateCreated: number;
  favoritesSnapshot: number[];
}

export class VisitorSubmission {
  @Transform(({value}) => dayjs.unix(value), {toClassOnly: true})
  dateCreated!: Dayjs;
  favoritesSnapshot!: number[];
}

import {Injectable} from '@angular/core';
import * as Bowser from 'bowser';
import {Parser} from 'bowser';
import Utils from 'bowser/src/utils';

@Injectable({
  providedIn: 'root',
})
export class BowserService {
  private readonly parserInstance: Parser.Parser = Bowser.getParser(window.navigator.userAgent);

  get parser(): Parser.Parser {
    return this.parserInstance;
  }

  satisfies(checkTree: Parser.checkTree): boolean | undefined {
    return this._customBrowsersSatisfies(checkTree) || this.parserInstance.satisfies(checkTree);
  }

  _customBrowsersSatisfies(checkTree: Parser.checkTree): boolean {
    return Object.keys(checkTree).some(
      browserName =>
        'function' === typeof this['_is_' + browserName] && this['_is_' + browserName](checkTree[browserName])
    );
  }

  _is_iOSFacebook(iOSVersion: string): boolean {
    return (
      this.parserInstance.is('iOS') &&
      this.parserInstance.is('Safari') &&
      !this.parserInstance.getBrowserVersion() &&
      this._compareOSVersion(iOSVersion)
    );
  }

  /**
   * Copy of the Bowser compareVersion method but changed to compare OS version;
   * @param {string} version
   * @returns {boolean}
   */
  _compareOSVersion(version: string): boolean {
    let expectedResults = [0];
    let comparableVersion = version;
    let isLoose = false;

    const currentOSVersion = this.parserInstance.getOSVersion();

    if (typeof currentOSVersion !== 'string') {
      return void 0;
    }

    if (version[0] === '>' || version[0] === '<') {
      comparableVersion = version.substr(1);
      if (version[1] === '=') {
        isLoose = true;
        comparableVersion = version.substr(2);
      } else {
        expectedResults = [];
      }
      if (version[0] === '>') {
        expectedResults.push(1);
      } else {
        expectedResults.push(-1);
      }
    } else if (version[0] === '=') {
      comparableVersion = version.substr(1);
    } else if (version[0] === '~') {
      isLoose = true;
      comparableVersion = version.substr(1);
    }

    return expectedResults.indexOf(Utils.compareVersions(currentOSVersion, comparableVersion, isLoose)) > -1;
  }
}

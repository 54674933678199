import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, catchError, combineLatest, map, Observable, of, shareReplay, tap} from 'rxjs';
import {IGalleryContext} from '../entities/gallery-context';
import {VisitorSubmission} from '../entities/visitor-submition';
import {plainToClass} from 'class-transformer';
import {FavoriteImageResponse} from '../entities/favorite-image-response';
import dayjs from 'dayjs';
import {IGalleryInfoFavorites} from '../entities/gallery-info-favorites.interface';
import {head} from 'ramda';
import {PlatformEnvironment} from '@px/shared/env';
import {FAVORITE_REMINDER_FEATURE_NAME} from '../consts/favorite-reminder-timeout-feature-name.const';
import {FavoritesDataService} from '../infrastructure/favorites-data.service';
import {PRODUCT_FAMILY, ProductFamily} from '@px/shared/data-access/product-product-family';

@Injectable()
export class VisitorFavoritesFacadeService {
  private readonly platform = inject(PlatformEnvironment);
  private readonly product: ProductFamily = inject(PRODUCT_FAMILY);
  private readonly favoritesDataService = inject(FavoritesDataService);

  private favoritesInternal$ = new BehaviorSubject<Record<number, boolean>>({});
  private lastSubmissionInternal$ = new BehaviorSubject<VisitorSubmission | null>(null);
  private collection: FavoriteImageResponse[] = [];
  private galleryInfo!: IGalleryInfoFavorites;

  favorites$: Observable<Record<number, boolean>> = this.favoritesInternal$.pipe(
    map(favorites => {
      const result: Record<number, boolean> = {};

      for (const photo of this.galleryInfo?.photos ?? []) {
        const id = photo.id;
        result[id] = favorites[id];
      }

      return result;
    })
  );

  lastSubmission$ = this.lastSubmissionInternal$.pipe(
    map(lastSubmission => {
      if (lastSubmission) {
        const submittedPhotoIds: number[] = lastSubmission.favoritesSnapshot;
        const photoIds = this.galleryInfo.photos.map(item => item.id);
        lastSubmission.favoritesSnapshot = submittedPhotoIds.filter(id => photoIds.includes(id));

        return lastSubmission;
      }

      return null;
    })
  );

  favoritesCount$: Observable<number | null> = this.favorites$.pipe(
    map(favorites => Object.values(favorites ?? {}).filter(status => status).length),
    shareReplay(1)
  );

  isFavoritesSubmitted$: Observable<boolean | null> = combineLatest([this.favorites$, this.lastSubmission$]).pipe(
    map(([favorites, lastSubmission]) => {
      let isFavoritesSubmitted: boolean;
      try {
        const lastSubmittedFavorites = lastSubmission?.favoritesSnapshot;
        const currentFavoritesArr = Object.entries(favorites ?? {})
          .filter(([, status]) => status)
          .map(([id]) => +id);

        const compareFn = (a: number, b: number): number => a - b;

        isFavoritesSubmitted = lastSubmittedFavorites?.length
          ? JSON.stringify(lastSubmittedFavorites.sort(compareFn)) ===
            JSON.stringify(currentFavoritesArr.sort(compareFn))
          : false;
      } catch (e) {
        isFavoritesSubmitted = false;
      }

      return isFavoritesSubmitted;
    }),
    shareReplay(1)
  );

  get favorites(): Record<number, boolean> | null {
    return this.favoritesInternal$.value;
  }

  lastSubmissionDate$: Observable<Date | null> = this.lastSubmissionInternal$.pipe(
    map(lastSubmission => (lastSubmission ? lastSubmission.dateCreated.toDate() : null)),
    shareReplay(1)
  );

  updateImageFavoriteState(
    imageCollectionId: string,
    imageId: number,
    state: boolean,
    favoritesUrl: string
  ): Observable<void> {
    const {TIMEOUT} = this.platform.FEATURE_CONFIG?.[FAVORITE_REMINDER_FEATURE_NAME] ?? {};

    const favorites = Object.assign({}, this.favoritesInternal$.value, {[imageId]: state});
    this.favoritesInternal$.next(favorites);
    return this.favoritesDataService
      .updateImageFavoriteFlag({
        imageCollectionId,
        imageId,
        state,
        favoritesActivityUrl: favoritesUrl,
        clientEmailTimeoutOverride: TIMEOUT || undefined,
        productFamily: this.product,
      })
      .pipe(
        tap((response: FavoriteImageResponse) => {
          this.collection = [...this.collection.filter(item => item.image.id !== response.image.id), response];
        }),
        map(() => undefined)
      );
  }

  updateFavorites(galleryInfo: IGalleryInfoFavorites): Observable<void> {
    this.galleryInfo = galleryInfo;
    const favorites: Record<number, boolean> = {};

    return this.favoritesDataService.collectionOwnFavorites(galleryInfo.imageCollectionId).pipe(
      //TODO handle error
      catchError(err => {
        console.error(err);
        return of([]);
      }),
      tap(collection => {
        this.collection = collection;

        for (const i of collection) {
          favorites[i.image.id] = i.state;
        }

        this.favoritesInternal$.next(favorites);
      }),
      map(() => undefined)
    );
  }

  clearFavorites(): void {
    this.favoritesInternal$.next({});
  }

  submitFavoritesSet(galleryContext: IGalleryContext, imageCollectionId: string): Observable<void> {
    try {
      const dateCreated = dayjs().unix();
      const favoritesSnapshot = Object.entries(this.favorites ?? {})
        .filter(([, state]) => state)
        .map(([id]) => Number(id));

      this.lastSubmissionInternal$.next(plainToClass(VisitorSubmission, {dateCreated, favoritesSnapshot}));
    } catch (e) {
      console.error(e);
    }

    return this.favoritesDataService
      .submitFavoritesSet(galleryContext, imageCollectionId, this.product)
      .pipe(map(() => undefined));
  }

  updateLastSubmittedCollection(imageCollectionId: string): Observable<void> {
    return this.favoritesDataService.collectionOwnSubmissions(imageCollectionId).pipe(
      catchError(err => {
        console.error(err);
        return of([]);
      }),
      map(
        (submissions: VisitorSubmission[]) =>
          head(submissions.sort((a, b) => (b.dateCreated.isAfter(a.dateCreated) ? 1 : -1))) ?? null
      ),
      tap(lastSubmission => this.lastSubmissionInternal$.next(lastSubmission)),
      map(() => undefined)
    );
  }

  hasFavoriteHistory(): boolean {
    return !!this.collection?.length;
  }
}

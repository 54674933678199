import {animate, keyframes, style, transition, trigger} from '@angular/animations';
import {TRANSITION_VALUE} from '../tokens';

export const mdsSlideHeight = trigger('mdsSlideHeight', [
  transition(':leave', [
    animate(
      TRANSITION_VALUE,
      keyframes([
        style({opacity: 1, offset: 0}),
        style({opacity: 0, height: '*', offset: 0.25}),
        style({opacity: 0, height: 0, offset: 1}),
      ])
    ),
  ]),
  transition(':enter', [
    animate(
      TRANSITION_VALUE,
      keyframes([
        style({opacity: 0, height: 0, offset: 0}),
        style({opacity: 0, height: '*', offset: 0.25}),
        style({opacity: 1, offset: 1}),
      ])
    ),
  ]),
]);

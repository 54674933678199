import {inject, Injectable} from '@angular/core';
import {CollectionArchiveDataService} from '../infrustructure/collection-archive-data.service';
import {
  IDownloadFavoritesPayload,
  IDownloadGalleryPayload,
  IDownloadImagePayload,
  IDownloadPinValidationPayload,
} from '../entities/models/download-media-payload.interface';
import {catchError, EMPTY, Observable, of, switchMap} from 'rxjs';
import {CollectionArchiveLinks} from '../entities/models/collection-archive-links.interface';
import {CollectionArchiveResolution} from '../entities/enums/collection-archive-resolution.enum';
import {CollectionDownloadType} from '../entities/enums/collection-download-type.enum';
import {ILinkItem} from '../entities/models/link-item.interface';
import {DownloadRequestSource} from '../entities/enums/download-request-source.enum';

@Injectable()
export class CollectionArchiveFacadeService {
  private readonly collectionArchiveDataService = inject(CollectionArchiveDataService);

  private packageArchiveByPhotographer(
    id: string,
    imageIds: number[],
    visitorEmail: string,
    source?: DownloadRequestSource
  ): Observable<CollectionArchiveLinks> {
    try {
      return this.collectionArchiveDataService.createArchive(
        id,
        CollectionArchiveResolution.ORIGINAL,
        undefined,
        imageIds,
        CollectionDownloadType.FAVORITES,
        visitorEmail,
        source
      );
    } catch (err) {
      console.error(err);
      return EMPTY;
    }
  }

  createLinks(domain: string, uri: string[]): ILinkItem[] {
    return uri
      .map(path => ({
        url: domain + path,
        basename: path.replace(/^.+\//, ''),
      }))
      .sort((a, b) => a.url.localeCompare(b.url, undefined, {numeric: true}));
  }

  downloadFavoritesByVisitor(form: IDownloadFavoritesPayload): Observable<CollectionArchiveLinks> {
    try {
      return this.collectionArchiveDataService
        .getArchiveLinks(
          form.collectionId,
          form.size,
          form.downloadPin,
          form.imageIds,
          CollectionDownloadType.FAVORITES
        )
        .pipe(
          switchMap((response: CollectionArchiveLinks) => {
            if (response.links.length) {
              return of(response);
            }

            return this.collectionArchiveDataService.createArchive(
              form.collectionId,
              form.size,
              form.downloadPin,
              form.imageIds,
              CollectionDownloadType.FAVORITES
            );
          })
        );
    } catch (e) {
      console.error(e);
      return EMPTY;
    }
  }

  downloadGalleryByVisitor(form: IDownloadGalleryPayload, withQuery = false): Observable<CollectionArchiveLinks> {
    try {
      if (!withQuery) {
        return this.collectionArchiveDataService.createArchivePublic(
          form.collectionId,
          form.size,
          form.downloadPin,
          CollectionDownloadType.COLLECTION,
          form.email
        );
      }

      return this.collectionArchiveDataService
        .getArchiveLinks(form.collectionId, form.size, form.downloadPin, [], CollectionDownloadType.COLLECTION)
        .pipe(
          switchMap((response: CollectionArchiveLinks) => {
            if (response.links.length) {
              return of(response);
            }

            return this.collectionArchiveDataService.createArchivePublic(
              form.collectionId,
              form.size,
              form.downloadPin,
              CollectionDownloadType.COLLECTION,
              form.email
            );
          })
        );
    } catch (e) {
      console.error(e);
      return EMPTY;
    }
  }

  getArchivesByPhotographer(id: string, imageIds: number[]): Observable<CollectionArchiveLinks> {
    try {
      return this.collectionArchiveDataService.getArchiveLinks(
        id,
        CollectionArchiveResolution.ORIGINAL,
        undefined,
        imageIds,
        CollectionDownloadType.FAVORITES
      );
    } catch (err) {
      console.error(err);

      return EMPTY;
    }
  }

  packageArchiveByPhotographerFromActivity(
    id: string,
    imageIds: number[],
    visitorEmail: string
  ): Observable<CollectionArchiveLinks> {
    return this.packageArchiveByPhotographer(id, imageIds, visitorEmail, DownloadRequestSource.GALLERY_ACTIVITY);
  }

  packageArchiveByPhotographerFromFavorites(
    id: string,
    imageIds: number[],
    visitorEmail: string
  ): Observable<CollectionArchiveLinks> {
    return this.packageArchiveByPhotographer(
      id,
      imageIds,
      visitorEmail,
      DownloadRequestSource.PHOTOGRAPHER_FAVORITES_VIEW
    );
  }

  validatePinByVisitor(form: IDownloadPinValidationPayload): Observable<boolean> {
    try {
      return this.collectionArchiveDataService.validateDownloadPin(form.collectionId, form.downloadPin).pipe(
        catchError(err => {
          console.error(err);
          return of(false);
        })
      );
    } catch (e) {
      console.error(e);
      return EMPTY;
    }
  }

  getImageDownloadLinkByVisitor(form: IDownloadImagePayload): Observable<string> {
    try {
      return this.collectionArchiveDataService.getImageDownloadLink(
        form.collectionId,
        form.imageId,
        form.size,
        form.downloadPin
      );
    } catch (e) {
      console.error(e);
      return EMPTY;
    }
  }
}

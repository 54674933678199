import {ModuleWithProviders, NgModule, Type} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiSnackbarDefaultComponent} from './components/ui-snackbar/ui-snackbar-default.component';
import {UiSnackbarService} from './services/ui-snackbar.service';
import {UI_SNACKBAR} from './tokens/ui-snackbar.token';
import {OverlayModule} from '@angular/cdk/overlay';
import {UiSnackbarTextComponent} from './components/ui-snackbar-text/ui-snackbar-text.component';
import {UI_SNACKBAR_CONTAINER} from './tokens/ui-container.token';
import {IUiSnackbarContainer} from './interfaces/ui-snackbar-container.interface';
import {PortalModule} from '@angular/cdk/portal';
import {UiButtonModule} from '@px/ui-components-px-button';
import {MdsIconComponent} from '@pui/components/icon-core';

@NgModule({
  imports: [CommonModule, OverlayModule, PortalModule, UiButtonModule, MdsIconComponent],
  declarations: [UiSnackbarDefaultComponent, UiSnackbarTextComponent],
  providers: [{provide: UI_SNACKBAR, useClass: UiSnackbarService}],
  exports: [UiSnackbarTextComponent],
})
export class UiSnackbarModule {
  static forRoot(container?: Type<IUiSnackbarContainer<unknown>>): ModuleWithProviders<UiSnackbarModule> {
    return {
      ngModule: UiSnackbarModule,
      providers: [{provide: UI_SNACKBAR_CONTAINER, useValue: container ?? UiSnackbarDefaultComponent}],
    };
  }
}

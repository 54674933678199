import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpEventType, HttpResponse} from '@angular/common/http';
import {filter, mergeMap, Observable, of, withLatestFrom} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {IBulkUploadOptions, IUploadOptions} from '../models/upload-options';
import {IFileUploadProcess} from '../models/file-upload-process';
import {PxFile} from '../models/px-file';
import {ISStreamSuccessUpload, IStreamFailedUpload, IStreamUploadResult} from '../models/stream-upload-result';
import {IFileUploader} from '../models/file-uploader';

@Injectable()
export class FileUploaderService implements IFileUploader {
  private readonly MAX_PARALLEL = 1;
  private readonly http = inject(HttpClient);

  upload<T = never>(file: PxFile, url: string, options?: IUploadOptions): Observable<IFileUploadProcess<T>> {
    const form = new FormData();
    const rawFile = file.rawFile;

    if (options?.formProperties) {
      for (const key in options.formProperties) {
        const prop = options.formProperties[key];
        if (typeof prop === 'function') {
          form.append(key, prop(file));
        } else {
          form.append(key, prop);
        }
      }
    }

    form.append(options?.formFilePropertyKey ?? 'file', rawFile);
    const startTime = performance.now();

    return this.http
      .post<T>(url, form, {
        ...options,
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        filter(event =>
          [HttpEventType.Sent, HttpEventType.UploadProgress, HttpEventType.Response].includes(event.type)
        ),
        map(event => {
          if (event.type === HttpEventType.Sent) {
            return {
              progress: 0,
              total: file.size,
              uploaded: 0,
              success: null,
              data: null,
              error: null,
              startTime,
              endTime: null,
            };
          }

          if (event.type === HttpEventType.UploadProgress) {
            return {
              progress: Math.min(event.loaded / file.size, 1),
              total: file.size,
              uploaded: Math.min(event.loaded, file.size),
              success: null,
              data: null,
              error: null,
              startTime,
              endTime: null,
            };
          }

          return {
            progress: 1,
            total: file.size,
            uploaded: file.size,
            success: true,
            data: (event as HttpResponse<T>).body,
            error: null,
            startTime,
            endTime: performance.now(),
          };
        }),
        catchError(response => {
          return of({
            progress: 1,
            total: file.size,
            uploaded: 0,
            success: false,
            data: null,
            error: response,
            startTime,
            endTime: performance.now(),
          });
        })
      );
  }

  bulkUpload<T>(files: PxFile[], url: string, options?: IBulkUploadOptions): Observable<IStreamUploadResult<T>> {
    const maxParallelUploads = options?.maxParallelUploads ?? this.MAX_PARALLEL;
    const fileProgressMap = new Map<PxFile, IFileUploadProcess<T>>();
    const success: ISStreamSuccessUpload<T>[] = [];
    const failed: IStreamFailedUpload[] = [];

    return of(...files).pipe(
      mergeMap(file => this.upload<T>(file, url, options).pipe(withLatestFrom(of(file))), maxParallelUploads),
      map(([value, file]) => {
        fileProgressMap.set(file, value);

        const progressSum = Array.from(fileProgressMap.values()).reduce((acc, cur) => acc + cur.progress, 0);
        const progress = progressSum / files.length;
        const finishedFiles = Array.from(fileProgressMap.values()).filter(p => p.success !== null).length;

        if (value.success === null) {
          return {
            progress,
            success: [...success],
            failed: [...failed],
            done: false,
          };
        }

        if (value.success) {
          success.push({
            file,
            response: value.data as T,
          });

          return {
            progress,
            success: [...success],
            failed: [...failed],
            done: finishedFiles === files.length,
          };
        } else {
          failed.push({
            file,
            error: value.error,
          });

          return {
            progress,
            success: [...success],
            failed: [...failed],
            done: finishedFiles === files.length,
          };
        }
      })
    );
  }
}

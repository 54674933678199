export const TRANSITION_IN = '{{duration}}ms ease';
export const TRANSITION_IN_VALUE = '250ms ease';
export const TRANSITION_OUT_VALUE = '250ms ease';
export const TRANSITION = '{{duration}}ms ease';
export const TRANSITION_OUT = '{{duration}}ms ease';
export const DURATION = {params: {duration: 250}};
export const DURATION_FAST = {params: {duration: 100}};
export const DURATION_VALUE = 250;
export const DURATION_FAST_VALUE = 100;
export const TRANSITION_VALUE = `${DURATION_VALUE}ms ease`;

import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import {catchError, Observable, tap, throwError} from 'rxjs';
import {Router} from '@angular/router';
import {PSFThemesPlatformEnvironment} from '@px/psf-themes/platform-env';
import {MaintenanceService} from './maintenance.service';

@Injectable()
export class MaintenanceModeInterceptor implements HttpInterceptor {
  constructor(
    private appConfig: PSFThemesPlatformEnvironment,
    private router: Router,
    private maintenanceService: MaintenanceService
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap(event => {
        if (this.isMaintenanceResponse(event)) {
          this.setMaintenanceMode();
        }
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      catchError((response: any) => {
        if (request.url.includes(this.appConfig.API_URL) && this.isMaintenanceError(response)) {
          this.setMaintenanceMode();
        }
        return throwError(response);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isMaintenanceResponse(event: HttpEvent<any>): boolean {
    return event instanceof HttpResponse && !!event.body.maintenance && event.url.includes(this.appConfig.API_URL);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isMaintenanceError(response: any): boolean {
    return response instanceof HttpErrorResponse && response.status === 503;
  }

  setMaintenanceMode(): void {
    this.maintenanceService.setMaintenanceMode = true;
  }
}

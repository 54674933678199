import {IImageCollection, ImageCollection} from './image-collection';
import {Transform, Type} from 'class-transformer';
import {IVisitor, Visitor} from './visitor';
import {ProductFamily} from '@px/shared/data-access/product-product-family';
import dayjs, {Dayjs} from 'dayjs';

export interface IFavoriteImageResponse {
  image: IImageCollection;
  state: boolean;
  visitor: IVisitor;
  dateCreated: number;
  dateUpdated: number;
}

export class FavoriteImageResponse {
  @Type(() => ImageCollection)
  image!: ImageCollection;
  state!: boolean;

  @Type(() => Visitor)
  visitor!: Visitor;

  @Transform(({value}) => dayjs.unix(value), {toClassOnly: true})
  dateCreated!: Dayjs;

  @Transform(({value}) => dayjs.unix(value), {toClassOnly: true})
  dateUpdated!: Dayjs;

  productFamily!: ProductFamily;
}

import {coerceNumberProperty} from '@angular/cdk/coercion';
import {booleanAttribute, Directive, EventEmitter, Input, Output} from '@angular/core';
import {ICanDisabled} from '@pui/cdk/can-disabled';

@Directive()
export class ButtonCore implements ICanDisabled {
  protected tabIndexInternal = 0;

  @Output() click$ = new EventEmitter<Event>();

  @Input({transform: booleanAttribute}) disabled = false;
  @Input({transform: booleanAttribute}) readonly = false;
  @Input({transform: booleanAttribute}) hasProgress = false;

  @Input()
  set tabIndex(value) {
    this.tabIndexInternal = coerceNumberProperty(value) >= 0 ? coerceNumberProperty(value) : -1;
  }
  get tabIndex(): number {
    return this.tabIndexInternal;
  }

  onClick(event: Event): void {
    if (this.disabled) return;
    this.click$.emit(event);
  }
}

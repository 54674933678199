import {VisitorStatus} from '../enums/visitor-status.enum';

export interface IVisitor {
  id: string;
  email: string;
  roles: string[];
  status: VisitorStatus;
}

export class Visitor implements IVisitor {
  email!: string;
  id!: string;
  roles!: string[];
  status!: VisitorStatus;
}

import {OverlayModule} from '@angular/cdk/overlay';
import {PortalModule} from '@angular/cdk/portal';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {GdsButtonComponent} from '@gds/components/button';
import {UiCaSnackbarContainerComponent} from './ui-ca-snackbar-container/ui-ca-snackbar-container.component';

@NgModule({
  imports: [CommonModule, OverlayModule, PortalModule, GdsButtonComponent],
  declarations: [UiCaSnackbarContainerComponent],
  exports: [UiCaSnackbarContainerComponent],
})
export class UiCaSnackbarContainerModule {}

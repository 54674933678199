import {HttpHeaders, HttpParams} from '@angular/common/http';
import {PxFile} from './px-file';

export type FormProperty = string | ((file: PxFile) => string);
export type FormProperties = Record<string, FormProperty>;

export interface IUploadOptions {
  headers?:
    | HttpHeaders
    | {
        [header: string]: string | string[];
      };
  params?:
    | HttpParams
    | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
      };
  formProperties?: Record<string, FormProperty>;
  formFilePropertyKey?: string;
}

export interface IBulkUploadOptions extends IUploadOptions {
  maxParallelUploads?: number;
}

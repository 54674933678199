import {Inject, Injectable, Optional} from '@angular/core';
import {SESSION_STORAGE_SALT} from '../tokens/session-storage-prefix.token';
import {ISessionStorageService} from '../entities/session-storage-service';

@Injectable()
export class SessionStorageService implements ISessionStorageService {
  constructor(@Optional() @Inject(SESSION_STORAGE_SALT) private readonly storageKeyPrefix: string) {}

  private getPrefixedKey(key: string): string {
    return this.storageKeyPrefix ? `${this.storageKeyPrefix}_${key}` : key;
  }

  getItem<T>(key: string): T | null {
    const storedItem = sessionStorage.getItem(this.getPrefixedKey(key));
    try {
      return storedItem ? JSON.parse(storedItem) : null;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  setItem<T>(key: string, value: T): void {
    sessionStorage.setItem(this.getPrefixedKey(key), JSON.stringify(value));
  }

  removeItem(key: string): void {
    sessionStorage.removeItem(this.getPrefixedKey(key));
  }
}

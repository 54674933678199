import {Injectable} from '@angular/core';
import {ThemePresetId, ThemePresets} from '../interfaces/theme-preset.interface';
import {Breakpoints} from './breakpoints.service';
import {ThemingEntityBase} from './theming-entity-base.service';

@Injectable()
export class ImageGridSpacingService extends ThemingEntityBase {
  private getProperty(name: string): string {
    return getComputedStyle(document.documentElement).getPropertyValue(`--${name}`);
  }

  private afterPresetsLoaded(): void {
    for (const [presetId, preset] of Object.entries(this.presets)) {
      preset.customProperties = {
        margin: parseInt(this.getProperty(presetId + 'Margin')),
        spacing: parseInt(this.getProperty(presetId + 'Spacing')),
        mobileMargin: parseInt(this.getProperty(presetId + 'MobileMargin')),
        mobileSpacing: parseInt(this.getProperty(presetId + 'MobileSpacing')),
        isMobileFullWidth: JSON.parse(this.getProperty(presetId + 'IsMobileFullWidth')),
      };
    }
    //todo: temporary hack to re-emit activePreset$
    this.activePresetId$.next(this.activePresetId);
  }

  protected initDefaultValues(): void {
    return;
  }

  override loadPresets(presets: ThemePresets, defaultPresetID: ThemePresetId = ''): void {
    super.loadPresets(presets, defaultPresetID);
    this.afterPresetsLoaded();
  }

  getSpacing(containerWidth: number): number {
    if (Breakpoints.isMobile(containerWidth)) {
      return this.activePreset?.customProperties?.mobileSpacing || 0;
    } else {
      return this.activePreset?.customProperties?.spacing || 0;
    }
  }

  getMargin(containerWidth: number): number {
    if (Breakpoints.isMobile(containerWidth)) {
      return this.activePreset?.customProperties?.mobileMargin || 0;
    } else {
      return this.activePreset?.customProperties?.margin || 0;
    }
  }

  isFullWidth(containerWidth: number): boolean {
    if (Breakpoints.isMobile(containerWidth)) {
      return this.activePreset?.customProperties?.isMobileFullWidth || false;
    } else {
      return false;
    }
  }
}

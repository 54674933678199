import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {catchError, map, Observable, of} from 'rxjs';
import {PlatformEnvironment} from '@px/shared/env';

@Injectable()
export class SaPingDataService {
  private readonly httpClient = inject(HttpClient);
  private readonly platform = inject(PlatformEnvironment);

  ping(): Observable<boolean> {
    return this.httpClient.get(this.platform.SA_GRAPH_QL_ENDPOINT, {observe: 'response'}).pipe(
      catchError((err: HttpErrorResponse) => {
        return of(err);
      }),
      map((response: HttpResponse<object> | HttpErrorResponse) => {
        return response.status !== 0;
      })
    );
  }
}

import {Injectable} from '@angular/core';
import {BehaviorSubject, catchError, map, Observable, of, shareReplay, tap} from 'rxjs';
import {IGalleryHiddenPhotosInfo} from '../entities/gallery-info.interface';
import {HiddenImageResponse} from '../entities/hidden-image-response';
import {HiddenPhotosDataService} from '../infrastructure/hidden-photos-data.service';

@Injectable()
export class HiddenPhotosFacadeService {
  private readonly hiddenPhotosInternal$ = new BehaviorSubject<Record<number, boolean>>({});

  private collection: HiddenImageResponse[] = [];
  private galleryInfo: IGalleryHiddenPhotosInfo;

  hiddenPhotos$: Observable<Record<number, boolean>> = this.hiddenPhotosInternal$.pipe(
    map((hiddenPhotos: Record<number, boolean>) => this.mapHiddenPhotosToGalleryPhotos(hiddenPhotos)),
    shareReplay(1)
  );

  hiddenPhotosCount$: Observable<number | null> = this.hiddenPhotos$.pipe(
    map(hiddenPhotos => Object.values(hiddenPhotos).filter(status => status).length),
    shareReplay(1)
  );

  get hiddenPhotos(): Record<number, boolean> {
    return this.hiddenPhotosInternal$.value;
  }

  constructor(private readonly hiddenPhotosDataService: HiddenPhotosDataService) {}

  mapHiddenPhotosToGalleryPhotos(hiddenPhotos: Record<number, boolean>): Record<number, boolean> {
    const result: Record<number, boolean> = {};

    for (const photo of this.galleryInfo?.photos ?? []) {
      const id = photo.id;

      if (hiddenPhotos[id]) {
        result[id] = hiddenPhotos[id];
      }
    }

    return result;
  }

  updateImageHiddenState(imageCollectionId: string, imageId: number, state: boolean): Observable<void> {
    const hiddenPhotos = Object.assign({}, this.hiddenPhotosInternal$.value, {
      [imageId]: state,
    });

    this.hiddenPhotosInternal$.next(hiddenPhotos);

    return this.hiddenPhotosDataService.updateHiddenPhotoFlag(imageCollectionId, imageId, state).pipe(
      tap(response => {
        this.collection = [...this.collection.filter(item => item.image.id !== response.image.id), response];
      }),
      map(() => undefined)
    );
  }

  updateHiddenPhotos(galleryHiddenPhotosInfo: IGalleryHiddenPhotosInfo): Observable<void> {
    this.galleryInfo = galleryHiddenPhotosInfo;

    return this.hiddenPhotosDataService.collectionHiddenPhotos(galleryHiddenPhotosInfo.imageCollectionId).pipe(
      catchError(err => {
        console.error(err);
        return of([]);
      }),
      tap((collections: HiddenImageResponse[]) => {
        this.collection = collections;

        const hiddenPhotos: Record<number, boolean> = {};

        for (const response of collections) {
          if (!response) continue;
          hiddenPhotos[response.image.id] = response.state;
        }

        this.hiddenPhotosInternal$.next(hiddenPhotos);
      }),
      map(() => undefined)
    );
  }

  hasHiddenHistory(): boolean {
    return !!this.collection?.length;
  }

  clearHiddenPhotos(): void {
    this.hiddenPhotosInternal$.next({});
  }
}

import {ChangeDetectionStrategy, Component, inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {WINDOW_TOKEN} from '@px/cdk/window';
import {IOAuthRouterData, PhotographerAuthFacade} from '@px/photographer-auth/domain';
import {RouterHelperService} from '@px/shared/data-access/router-helper';
import {CommunicationProtocolService} from '@px/shared/data-access/state-communication-protocol';
import {ISession} from '@px/shared/session-provider';
import {catchError, EMPTY} from 'rxjs';

@Component({
  selector: 'px-code',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CommunicationProtocolService],
})
@UntilDestroy()
export class CodeComponent implements OnInit {
  private readonly route = inject(ActivatedRoute);
  private readonly router = inject(Router);
  private readonly photographerAuthFacade = inject(PhotographerAuthFacade);
  private readonly routerHelper = inject(RouterHelperService);
  private readonly window = inject(WINDOW_TOKEN);
  private readonly communicationProtocolService = inject(CommunicationProtocolService);

  private readonly rootLevelPath = '/';
  private readonly sessionParam = 'session';
  private readonly stateParam = 'state';

  ngOnInit(): void {
    const {code, state} = this.route.snapshot.queryParams;

    this.photographerAuthFacade
      .oAuth(code)
      .pipe(
        catchError(err => {
          console.error(err);

          const params = this.routerHelper.mergeDataInTree<IOAuthRouterData>(this.route);

          this.router.navigate([params.fallbackUrl]);

          return EMPTY;
        }),
        untilDestroyed(this)
      )
      .subscribe((session: ISession) => {
        this.handleOAuthSuccess(session, state);
      });
  }

  handleOAuthSuccess(session: ISession, state: string): void {
    const payload = {
      token: session.token,
      access: session.access,
      expiration: session.expiration,
      refreshId: session.refreshId,
    };

    const sessionHash = this.window.btoa(JSON.stringify(payload));
    const stateParsed = this.communicationProtocolService.parseState(state);
    let nextUrl = typeof stateParsed === 'string' ? stateParsed : (stateParsed?.referer ?? this.rootLevelPath);

    if (nextUrl.startsWith(this.rootLevelPath)) {
      nextUrl = this.window.location.origin.concat(nextUrl);
    }

    const url = new URL(nextUrl);

    url.searchParams.append(this.sessionParam, sessionHash);

    if (typeof stateParsed !== 'string') {
      url.searchParams.append(this.stateParam, state);
    }

    if (nextUrl.startsWith(this.window.location.origin)) {
      this.router.navigateByUrl(`${url.pathname}${url.search}`, {replaceUrl: true});
      return;
    }

    this.window.location.href = url.toString();
  }
}

import {HttpErrorResponse} from '@angular/common/http';

export interface IFileUploadProcess<T> {
  progress: number;
  total: number;
  uploaded: number;
  success: boolean | null;
  data: T | null;
  error: HttpErrorResponse | null;
  startTime: number | null;
  endTime: number | null;
}

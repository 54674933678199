import {animate, style, transition, trigger} from '@angular/animations';
import {DURATION, DURATION_FAST, TRANSITION_IN, TRANSITION_OUT} from '../tokens';

export const mdsFadeInOut = trigger('mdsFadeInOut', [
  transition(
    '* => enter',
    [style({opacity: 0, transform: 'scale(0.95)'}), animate(TRANSITION_IN, style({opacity: 1, transform: 'scale(1)'}))],
    DURATION_FAST
  ),
  transition(
    '* => exit',
    [
      style({opacity: 1, transform: 'scale(1)'}),
      animate(TRANSITION_OUT, style({opacity: 0, transform: 'scale(0.95)'})),
    ],
    DURATION_FAST
  ),
]);

export const puiFadeInOutDisplayAnim = trigger('puiFadeInOutDisplayAnim', [
  transition(
    ':enter',
    [style({opacity: 0, visibility: 'hidden'}), animate(TRANSITION_IN, style({visibility: 'visible', opacity: 1}))],
    DURATION
  ),
  transition(
    ':leave',
    [style({position: 'absolute'}), animate(TRANSITION_OUT, style({opacity: 0, visibility: 'hidden'}))],
    DURATION
  ),
]);

import {Inject, Injectable} from '@angular/core';
import {MarginSize} from '../enums/margin-size.enum';
import {IGridConfigService} from './grid-config-service.interface';
import {GridSize} from '../enums/grid-size.enum';
import {IGridConfig, IGridConfigBase} from '../interfaces/grid-config.interface';
import {mergeDeepLeft} from 'ramda';
import {MARGIN_SIZE_VARIANTS} from '../consts/margin-size-variants.const';
import {GRID_SIZE_VARIANTS} from '../consts/grid-size-variants.const';
import {WINDOW_TOKEN} from '@px/cdk/window';

@Injectable()
export class GridConfigService implements IGridConfigService {
  constructor(@Inject(WINDOW_TOKEN) protected readonly window: Window) {}

  getGridConfig(
    marginSize: MarginSize,
    gridSize: GridSize
  ): {config: IGridConfig; params: {gridSize: GridSize; marginSize: MarginSize}} {
    return {
      config: mergeDeepLeft(GRID_SIZE_VARIANTS[gridSize], MARGIN_SIZE_VARIANTS[marginSize]),
      params: {
        gridSize,
        marginSize,
      },
    };
  }

  getCurrentBreakpoint(config: IGridConfig): IGridConfigBase {
    const breakpoints = [2560, 1280, 768];
    let {columns, margin} = config;

    if (config?.breakAt) {
      for (const breakpoint of breakpoints) {
        if (this.window.innerWidth >= breakpoint) {
          margin = config.breakAt[breakpoint]?.margin ?? margin;
          columns = config.breakAt[breakpoint]?.columns ?? columns;
          break;
        }
      }
    }

    return {margin, columns};
  }
}

import {UiSnackbarRef} from '../models/ui-snackbar-ref';
import {IUiSnackbarConfig} from './ui-snackbar-config.interface';
import {ComponentType} from '@angular/cdk/portal';
import {UiSnackbarTextComponent} from '../components/ui-snackbar-text/ui-snackbar-text.component';
import {Observable} from 'rxjs';
import {IUiSnackbarDataText} from './ui-snackbar-data-text.interface';

export interface IUiSnackbarService {
  open<C, D, R>(component: ComponentType<C>, config?: IUiSnackbarConfig<D>): Observable<UiSnackbarRef<C, R>>;
  text<D extends IUiSnackbarDataText>(
    text: string,
    config?: IUiSnackbarConfig<D>
  ): Observable<UiSnackbarRef<UiSnackbarTextComponent, void>>;
}

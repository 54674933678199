import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject, filter, withLatestFrom} from 'rxjs';
import {PSFThemesPlatformEnvironment} from '@px/psf-themes/platform-env';

declare let gtag: (...args: unknown[]) => void;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {
  private gaPageView = new BehaviorSubject(null);

  constructor(
    private platform: PSFThemesPlatformEnvironment,
    public router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    router.events
      .pipe(
        filter(event => typeof gtag === 'function' && event instanceof NavigationEnd),
        withLatestFrom(activatedRoute.fragment),
        filter(([, fragment]) => fragment !== platform.PREVIEW_URL_FRAGMENT)
      )
      .subscribe(this.gaPageView);
  }

  init(TRACKING_ID: string): void {
    if (!this.platform.GA_ENABLED) {
      return;
    }

    // Detect iPad device
    let ga_message = 'No';
    if (
      // iPad Pro:
      (window.navigator.maxTouchPoints &&
        window.navigator.maxTouchPoints > 2 &&
        window.navigator.userAgent.includes('Macintosh')) ||
      // iPad prior to iPad Pro / iOS 13:
      window.navigator.userAgent.includes('iPad')
    )
      ga_message = 'Yes';
    else ga_message = 'No';

    // GA congif
    gtag('config', TRACKING_ID, {
      send_page_view: false,
      custom_map: {dimension1: 'ipad-os'},
    });

    // Send GA custom dimension 'ipad-os'
    gtag('event', 'ipad_os_dimension', {
      'ipad-os': ga_message,
    });

    this.gaPageView.pipe(filter(event => !!event)).subscribe(([event]) => {
      try {
        gtag('config', TRACKING_ID, {page_path: event.urlAfterRedirects});
      } catch (e) {
        console.warn(e);
      }
    });
  }
}

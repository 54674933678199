import {NgClass, NgIf} from '@angular/common';
import {booleanAttribute, ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {mdsSlideHeight} from '@pui/cdk/animations';
import {MdsBannerType} from './banner.types';

@Component({
  selector: 'mds-banner',
  standalone: true,
  imports: [NgIf, NgClass],
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [mdsSlideHeight],
})
export class MdsBannerComponent {
  @Input() type: MdsBannerType | undefined;
  @Input({transform: booleanAttribute}) isVisible = true;
  @Input({transform: booleanAttribute}) isCentered = false;
}

import {inject, Injectable} from '@angular/core';

import {Apollo, gql} from 'apollo-angular';
import {filter, map, Observable} from 'rxjs';
import {plainToInstance} from 'class-transformer';
import {ProductFamily} from '@px/shared/data-access/product-product-family';
import {IFavoriteImagePayload} from '../entities/favorite-image-payload';
import {FavoriteImageResponse, IFavoriteImageResponse} from '../entities/favorite-image-response';
import {IVisitorSubmission, VisitorSubmission} from '../entities/visitor-submition';
import {IGalleryContext} from '../entities/gallery-context';
import {IVisitorSubmissionsResponse, VisitorSubmissionsResponse} from '../entities/visitor-submissions-response';

@Injectable()
export class FavoritesDataService {
  private readonly apollo = inject(Apollo);

  private readonly updateImageFavoriteFlagMutation = gql<
    {updateImageFavoriteFlag: IFavoriteImageResponse},
    {input: IFavoriteImagePayload}
  >`
    mutation UpdateImageFavoriteFlag($input: UpdateImageFavoriteFlagInput!) {
      updateImageFavoriteFlag(input: $input) {
        image {
          picId
        }
        state
      }
    }
  `;

  private readonly submitFavoritesSetMutation = gql<
    boolean,
    {galleryContext: IGalleryContext; imageCollectionId: string; productFamily: ProductFamily}
  >`
    mutation SubmitFavoritesSet(
      $galleryContext: GalleryContext!
      $imageCollectionId: String!
      $productFamily: ProductFamily!
    ) {
      submitFavoritesSet(
        galleryContext: $galleryContext
        imageCollectionId: $imageCollectionId
        productFamily: $productFamily
      )
    }
  `;

  // TODO: Change return type
  private collectionFavoritesQuery = gql<
    IFavoriteImageResponse[],
    {imageCollectionId: string; productFamily: ProductFamily; visitorId: string}
  >`
    query CollectionFavorites($imageCollectionId: String!, $productFamily: ProductFamily!, $visitorId: String) {
      collectionFavorites(imageCollectionId: $imageCollectionId, productFamily: $productFamily, visitorId: $visitorId) {
        image {
          picId
        }
        state
      }
    }
  `;

  private collectionOwnSubmissionsQuery = gql<
    {collectionOwnSubmissions: IVisitorSubmission[]},
    {imageCollectionId: string}
  >`
    query CollectionOwnSubmissions($imageCollectionId: String!) {
      collectionOwnSubmissions(imageCollectionId: $imageCollectionId) {
        dateCreated
        favoritesSnapshot
      }
    }
  `;

  private collectionOwnFavoritesQuery = gql<
    {collectionOwnFavorites: IFavoriteImageResponse[]},
    {imageCollectionId: string}
  >`
    query CollectionOwnFavorites($imageCollectionId: String!) {
      collectionOwnFavorites(imageCollectionId: $imageCollectionId) {
        image {
          picId
        }
        state
      }
    }
  `;

  private visitorsSubmissions = gql<
    {visitorsCollectionActivity: IVisitorSubmissionsResponse[]},
    {imageCollectionId: string; productFamily: ProductFamily}
  >`
    query VisitorsCollectionActivity($imageCollectionId: String!, $productFamily: ProductFamily!) {
      visitorsCollectionActivity(imageCollectionId: $imageCollectionId, productFamily: $productFamily) {
        submissions {
          dateCreated
          favoritesSnapshot
        }
        visitor {
          id
          status
          email
          roles
        }

        favorites {
          image {
            picId
            originalFileName
          }

          dateUpdated
          dateCreated
          state
        }
      }
    }
  `;

  collectionFavorites(
    imageCollectionId: string,
    productFamily: ProductFamily,
    visitorId: string
  ): Observable<FavoriteImageResponse[]> {
    return this.apollo
      .query({
        query: this.collectionFavoritesQuery,
        variables: {
          imageCollectionId,
          productFamily,
          visitorId,
        },
        errorPolicy: 'all',
      })
      .pipe(
        filter(result => !!result.data),
        map(({data}) => plainToInstance(FavoriteImageResponse, data))
      );
  }

  collectionOwnFavorites(imageCollectionId: string): Observable<FavoriteImageResponse[]> {
    return this.apollo
      .query({
        query: this.collectionOwnFavoritesQuery,
        variables: {imageCollectionId},
        errorPolicy: 'all',
      })
      .pipe(
        filter(result => !!result.data),
        map(({data}) => plainToInstance(FavoriteImageResponse, data?.collectionOwnFavorites))
      );
  }

  submitFavoritesSet(
    galleryContext: IGalleryContext,
    imageCollectionId: string,
    productFamily: ProductFamily
  ): Observable<boolean> {
    return this.apollo
      .mutate({
        mutation: this.submitFavoritesSetMutation,
        variables: {
          galleryContext,
          imageCollectionId,
          productFamily,
        },
      })
      .pipe(
        filter(result => !!result.data),
        map(({data}) => !!data)
      );
  }

  updateImageFavoriteFlag(input: IFavoriteImagePayload): Observable<FavoriteImageResponse> {
    return this.apollo
      .mutate({
        mutation: this.updateImageFavoriteFlagMutation,
        variables: {
          input,
        },
      })
      .pipe(
        filter(result => !!result.data),
        map(({data}) => plainToInstance(FavoriteImageResponse, data?.updateImageFavoriteFlag))
      );
  }

  collectionOwnSubmissions(imageCollectionId: string): Observable<VisitorSubmission[]> {
    return this.apollo
      .query({
        query: this.collectionOwnSubmissionsQuery,
        variables: {
          imageCollectionId,
        },
        errorPolicy: 'all',
      })
      .pipe(
        filter(result => !!result.data),
        map(result => plainToInstance(VisitorSubmission, result.data?.collectionOwnSubmissions))
      );
  }

  getVisitorsSubmissions(imageCollectionId: string): Observable<VisitorSubmissionsResponse[]> {
    return this.apollo
      .query({
        query: this.visitorsSubmissions,
        variables: {imageCollectionId, productFamily: ProductFamily.PSF},
        errorPolicy: 'all',
      })
      .pipe(
        filter(result => !!result),
        map(result => plainToInstance(VisitorSubmissionsResponse, result.data?.visitorsCollectionActivity))
      );
  }

  getVisitorsSubmissionsWatcher(imageCollectionId: string, interval: number): Observable<VisitorSubmissionsResponse[]> {
    return this.apollo
      .watchQuery({
        query: this.visitorsSubmissions,
        variables: {imageCollectionId, productFamily: ProductFamily.PSF},
        pollInterval: interval,
        errorPolicy: 'all',
      })
      .valueChanges.pipe(
        filter(result => !!result),
        map(result => plainToInstance(VisitorSubmissionsResponse, result.data?.visitorsCollectionActivity))
      );
  }

  dropFavoritesCache(): void {
    this.apollo.client.cache.evict({id: 'ROOT_QUERY', fieldName: 'visitorsCollectionActivity'});
    this.apollo.client.cache.gc();
  }
}

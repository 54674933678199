import {NgModule} from '@angular/core';
import {FileUploaderService} from './services/file-uploader.service';
import {FileValidatorService} from './services/file-validator.service';
import {FileFactoryService} from './services/file-factory.service';
import {FileSelectorDirective} from './directives/file-selector.directive';
import {FileDropSelectorDirective} from './directives/file-drop-selector.directive';
import {WINDOW_PROVIDER} from '@px/cdk/window';
import {ImageFileService} from './services/image-file.service';

@NgModule({
  providers: [FileUploaderService, FileValidatorService, FileFactoryService, ImageFileService, WINDOW_PROVIDER],
  declarations: [FileSelectorDirective, FileDropSelectorDirective],
  exports: [FileSelectorDirective, FileDropSelectorDirective],
})
export class FeatureFileUploadModule {}

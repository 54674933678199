export interface ISocialLinks {
  contactEmail: string;
  facebook: string;
  instagram: string;
  pinterest: string;
  tiktok: string;
  twitter: string;
}

export class SocialLinks implements ISocialLinks {
  contactEmail!: string;
  facebook!: string;
  instagram!: string;
  pinterest!: string;
  tiktok!: string;
  twitter!: string;
}

import {MarginSize} from '../enums/margin-size.enum';

export const MARGIN_SIZE_VARIANTS = {
  [MarginSize.sm]: {
    margin: 4,
    breakAt: {
      768: {
        margin: 6,
      },
    },
  },
  [MarginSize.md]: {
    margin: 14,
    breakAt: {
      768: {
        margin: 16,
      },
    },
  },
};

import {ChangeDetectionStrategy, Component, Inject, Input, Optional, TemplateRef} from '@angular/core';
import {UI_SNACKBAR_DATA} from '../../tokens/ui-snackbar-data.token';
import {IUiSnackbarDataText} from '../../interfaces/ui-snackbar-data-text.interface';
import {UiSnackbarRef} from '../../models/ui-snackbar-ref';
import {IUiSnackbarTextButtonRefContext} from '../../interfaces/ui-snackbar-text-button-ref.interface';
import {MdsIcon} from '@pui/components/icon-core';

@Component({
  selector: 'px-ui-snackbar-text',
  templateUrl: './ui-snackbar-text.component.html',
  styleUrls: ['./ui-snackbar-text.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiSnackbarTextComponent {
  private textInstance!: string;
  private actionInstance!: string;
  private iconInstance!: MdsIcon;

  @Input() buttonTemplateRef!: TemplateRef<IUiSnackbarTextButtonRefContext>;

  get text(): string {
    return this.textInstance ?? this.data?.text;
  }
  @Input()
  set text(value: string) {
    this.textInstance = value;
  }

  get action(): string {
    return this.actionInstance ?? this.data?.action;
  }
  @Input()
  set action(value: string) {
    this.actionInstance = value;
  }

  get icon(): MdsIcon {
    return this.iconInstance ?? this.data?.icon;
  }
  @Input()
  set icon(value: MdsIcon) {
    this.iconInstance = value;
  }

  constructor(
    @Optional() @Inject(UI_SNACKBAR_DATA) private readonly data: {text: string} & IUiSnackbarDataText,
    @Optional() private readonly uiSnackbarRef: UiSnackbarRef<UiSnackbarTextComponent, void>
  ) {}

  call = (): void => {
    this.uiSnackbarRef?.close();
  };
}

import {ACCOUNT_FEATURE_NANE} from '@px/shared-account';
import {DOWNLOAD_ARCHIVE_FEATURE_NANE} from '@px/psf-themes-collection-archive';
import {CONNECTION_ISSUE_FEATURE_NAME} from '@px/connection-issue/domain';
import {EMAIL_REGISTRATION_FEATURE_NANE} from '@px/psf-themes-email-registration';
import {FeatureConfigBase, IPlatformConfig} from '@px/shared/env';
import {UPDATE_NOTIFICATION_FEATURE_NAME} from '@px/update-notification/feature-update-notification';

export interface IPSFThemesPlatformConfig extends IPlatformConfig<PSFThemesFeatureList, PSFThemesFeatureConfig> {
  apiMockServerURL: string | null;
  debugHideHeroLoader: boolean;
  debugEnableFragments: boolean;
  debugImageSize: number;
  debugForceGalleryURL: string;
  debugUseMockServer: boolean;
  debugShowGitCommits: boolean;
}

export enum PSFThemesPlatformFeatures {
  EXP_NEW_GRID = 'EXP_NEW_GRID',
  CA_EMAIL_REGISTRATION = EMAIL_REGISTRATION_FEATURE_NANE,
  CA_FAV_DOWNLOADS_ARCHIVE = DOWNLOAD_ARCHIVE_FEATURE_NANE,
  ACCOUNT = ACCOUNT_FEATURE_NANE,
  CONNECTION_ISSUE = CONNECTION_ISSUE_FEATURE_NAME,
  UPDATE_NOTIFICATION = UPDATE_NOTIFICATION_FEATURE_NAME,
  UNSUPPORTED_BROWSER_OVERRIDE = 'UNSUPPORTED_BROWSER_OVERRIDE',
  CHECK_UPDATES = 'CHECK_UPDATES',
  PLAY = 'PLAY',
  HIDE_COVER = 'HIDE_COVER',
  P1_INTEGRATION = 'P1_INTEGRATION',
  PEOPLE_NAVIGATION = 'PEOPLE_NAVIGATION',
  GQL_API_CA = 'GQL_API_CA',
}

export type PSFThemesFeatureList = keyof typeof PSFThemesPlatformFeatures;

export type PSFThemesFeatureConfig = Record<
  PSFThemesFeatureList,
  | FeatureConfigBase
  | (FeatureConfigBase & {INTERVAL: number})
  | (FeatureConfigBase & {peopleAtBottom: boolean; peopleInSection: boolean})
  | (FeatureConfigBase & {peopleAvatarQualityThreshold: number})
>;

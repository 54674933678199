<button
  [class.is-active]="active"
  [class.is-disabled]="disabled"
  [ngClass]="hostClass"
  class="c-button"
  type="button"
  [disabled]="disabled"
  (click)="click$.emit()"
>
  <ng-template #iconContentTpl>
    <ng-content select="mds-icon"></ng-content>
  </ng-template>

  <ng-container *ngIf="hasIconLeft">
    <span class="c-button__icon c-button__icon--left">
      <ng-container *ngTemplateOutlet="iconContentTpl" />
    </span>
  </ng-container>

  <ng-container *ngIf="!isIcon; else iconTpl">
    <span class="c-button__text">
      <ng-content></ng-content>
    </span>
  </ng-container>

  <ng-template #iconTpl>
    <span class="c-button__icon">
      <ng-container *ngTemplateOutlet="iconContentTpl" />
    </span>
  </ng-template>

  <ng-container *ngIf="hasIconRight">
    <span class="c-button__icon c-button__icon--right">
      <ng-container *ngTemplateOutlet="iconContentTpl" />
    </span>
  </ng-container>

  <div *ngIf="hasProgress" class="c-button__progress-bar"></div>
</button>

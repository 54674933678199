<div
  class="c-snackbar"
  [style.transformOrigin]="transformOrigin"
  [@dialogContainer]="animationState"
  (@dialogContainer.start)="onAnimationStart($event)"
  (@dialogContainer.done)="onAnimationDone($event)"
>
  <ng-template cdkPortalOutlet></ng-template>
  <ng-content></ng-content>
</div>

<ng-template #buttonTemplateRef let-call="call" let-action>
  <gds-button *ngIf="action" variant="naked" class="c-snackbar__action c-snackbar__button" (click$)="call()">
    {{ action }}
  </gds-button>
</ng-template>

<mds-banner [isVisible]="isShown" class="update-notification-banner">
  <ng-container ngProjectAs="message">
    {{ 'Refresh the page to get an updated version' | translate }}
  </ng-container>
  <ng-container ngProjectAs="action">
    <div class="update-notification-banner__refresh">
      <mds-button color="neutral" (click$)="refresh()">
        {{ 'Refresh' | translate }}
      </mds-button>
    </div>
  </ng-container>
</mds-banner>

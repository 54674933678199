import {ChangeDetectionStrategy, Component} from '@angular/core';
import {PuiButtonCore} from './button-core';

@Component({
  selector: 'pui-button-core',
  standalone: true,
  templateUrl: './button-core.component.html',
  styleUrls: ['./button-core.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PuiButtonCoreComponent extends PuiButtonCore {}

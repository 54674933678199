import {Injectable} from '@angular/core';
import {ActivatedRoute, Data} from '@angular/router';
import {IDataRouter} from '../interfaces/data-router.interface';

@Injectable()
export class RouterHelperService {
  findParentDialog(activatedRoute: ActivatedRoute): ActivatedRoute | null {
    let parent: ActivatedRoute | null = null;
    let route: ActivatedRoute | null = null;

    while (parent === null) {
      route = route ? route.parent : activatedRoute.parent;

      const data: IDataRouter | undefined = route?.snapshot.data as IDataRouter;

      if (data?.isParent) {
        parent = route;
      }
    }

    return parent;
  }

  mergeDataInTree<T extends Data>(activatedRoute: ActivatedRoute): T {
    let route: ActivatedRoute | null = activatedRoute;

    const data: T[] = [];

    while (route?.parent) {
      data.push(route?.snapshot.data as T);

      route = route?.parent;
    }

    return data
      .reverse()
      .filter(Boolean)
      .reduce((acc, curr) => ({...acc, ...curr}), {} as T);
  }
}

import {Injectable} from '@angular/core';
import {ILocalStorageService} from '@px/shared-data-access-local-storage';

@Injectable()
export class IframeLocalstorageServiceService implements ILocalStorageService {
  getItem(key: string): string | null {
    console.warn('you are using IframeLocalstorageServiceService for setting localstorage key');
    return null;
  }
  setItem(key: string, value: string): void {
    console.warn('you are using IframeLocalstorageServiceService for getting localstorage key');
  }
  removeItem(key: string): void {
    console.warn('you are using IframeLocalstorageServiceService for removing localstorage key');
  }

  getSerialized<T>(key: string): T | null {
    console.warn('you are using IframeLocalstorageServiceService for getting serialized localstorage key');
    return null;
  }

  setSerialized<T>(key: string, value: T): void {
    console.warn('you are using IframeLocalstorageServiceService for setting serialized localstorage key');
  }
}

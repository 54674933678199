<ng-container *ngIf="!!href; else buttonTmpl">
  <a
    data-qa="button"
    class="c-button"
    [attr.href]="href"
    [attr.target]="target"
    rel="noopener noreferrer"
    [ngClass]="hostClass"
    [class.disabled]="disabled"
    [tabIndex]="tabIndex"
    (click)="onClick($event)"
    (keydown.enter)="onClick($event)"
    [style.min-width]="minWidth"
  >
    <ng-container *ngTemplateOutlet="btnContentTmpl" />
  </a>
</ng-container>

<ng-template #buttonTmpl>
  <button
    class="c-button"
    [disabled]="disabled"
    [ngClass]="hostClass"
    [tabIndex]="tabIndex"
    [type]="buttonType"
    (click)="onClick($event)"
    (keydown.enter)="onClick($event)"
    [style.min-width]="minWidth"
  >
    <ng-container *ngTemplateOutlet="btnContentTmpl" />
  </button>
</ng-template>

<ng-template #btnContentTmpl>
  <div class="c-button__inner">
    <div class="c-button__icon">
      <div class="c-button__icon-inner">
        <ng-content select="mds-icon,.mds-icon"></ng-content>
      </div>
    </div>
    <div class="c-button__text">
      <ng-content></ng-content>
    </div>
  </div>
  <span class="c-button__hover"></span>
  <span class="c-button__hover-2"></span>
</ng-template>

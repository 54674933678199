import {booleanAttribute, Directive, EventEmitter, Input, Output} from '@angular/core';

@Directive()
export class PuiButtonCore {
  @Output() click$ = new EventEmitter<Event>();

  @Input() buttonType?: 'button' | 'submit' | 'reset' = 'button';
  @Input() tabIndex = 0;
  @Input({transform: booleanAttribute}) disabled = false;
  @Input({transform: booleanAttribute}) loading = false;
  @Input({transform: booleanAttribute}) fullWidth = false;

  click(event: Event): void {
    if (this.disabled) return;
    this.click$.emit(event);
  }
}

/**
 * Imports the breakpoint values from the _breakpoints.scss file.
 * TODO: Marry with apps/psf-themes/src/assets/scss/_breakpoints.scss
 */
export class Breakpoints {
  static readonly mobile = 0;
  static readonly largeMobile = 576;
  static readonly tablet = 768;
  static readonly desktop = 1280;
  static readonly largeDesktop = 2560;

  static all = new Map<string, number>([
    ['mobile', Breakpoints.mobile],
    ['largeMobile', Breakpoints.largeMobile],
    ['tablet', Breakpoints.tablet],
    ['desktop', Breakpoints.desktop],
    ['largeDesktop', Breakpoints.largeDesktop],
  ]);

  private static getProperty(name: string): string {
    return getComputedStyle(document.documentElement).getPropertyValue(`--${name}`);
  }

  /**
   * Get the breakpoint name for the specified width.
   */
  static getBreakpointName(width: number): string {
    const breakpointEntries = [...Breakpoints.all.entries()];
    let ret = breakpointEntries[breakpointEntries.length - 1][0];
    for (let i = breakpointEntries.length - 1; i >= 0; i--) {
      const value = breakpointEntries[i][1];
      if (width >= value) {
        ret = breakpointEntries[i][0];
        break;
      }
    }
    return ret;
  }

  static isMobile(width: number): boolean {
    return ['mobile', 'largeMobile'].includes(Breakpoints.getBreakpointName(width));
  }

  static isTablet(width: number): boolean {
    return Breakpoints.getBreakpointName(width) === 'tablet';
  }

  static isDesktop(width: number): boolean {
    return ['desktop', 'largeDesktop'].includes(Breakpoints.getBreakpointName(width));
  }

  /**
   * Returns the map containing all breakpoints; map entries sorted by the breakpoint width.
   */
  static getAll(): Breakpoints {
    return Breakpoints.all;
  }
}

import {Inject, Injectable, Optional} from '@angular/core';
import {AccountDataService} from '../infrastructure/account-data.service';
import {EMPTY, filter, map, Observable, switchMap, tap} from 'rxjs';
import {Account, IAccountInput, PublicAccount} from '../entities';
import {FileUploaderService, IFileUploadProcess, PxFile} from '@px/shared-data-access-file-upload';
import {WINDOW_TOKEN} from '@px/cdk/window';

@Injectable()
export class AccountFacadeService {
  constructor(
    private readonly accountDataService: AccountDataService,
    @Inject(WINDOW_TOKEN) private readonly window: typeof globalThis,
    @Optional() private readonly fileUploaderService?: FileUploaderService
  ) {}

  getMyAccount(withSubscription = false): Observable<Account> {
    return withSubscription
      ? this.accountDataService.getMyAccountWithSubscriptionInfo()
      : this.accountDataService.getMyAccount();
  }

  getPublicAccount(pxId: number): Observable<PublicAccount> {
    return this.accountDataService.getPublicAccount(pxId);
  }

  updateAccount(accountInput: Partial<IAccountInput>, email: string, pxId: number): Observable<Account> {
    return this.accountDataService.updateAccount(accountInput, email, pxId);
  }

  uploadAvatar(file: File): Observable<string> {
    return this.accountDataService.logoUploadParameters(file.name).pipe(
      switchMap(params => {
        const objectUrl = this.window.URL.createObjectURL(file);

        return (
          this.fileUploaderService
            ?.upload(new PxFile(file, objectUrl), params.url, {
              formProperties: {...params.fields},
            })
            .pipe(
              filter((response: IFileUploadProcess<File>) => !!response.success),
              map(() => params.urlAfterUpload),
              tap(() => this.window.URL.revokeObjectURL(objectUrl))
            ) ?? EMPTY
        );
      })
    );
  }

  invalidateMyAccount(): void {
    this.accountDataService.invalidateMyAccount();
  }
}

import {IBulkUploadOptions, IUploadOptions} from './upload-options';
import {IFileUploadProcess} from './file-upload-process';
import {IStreamUploadResult} from './stream-upload-result';
import {PxFile} from './px-file';
import {Observable} from 'rxjs';

export interface IFileUploader {
  upload<T = never>(file: PxFile, url: string, options?: IUploadOptions): Observable<IFileUploadProcess<T>>;
  bulkUpload<T>(files: PxFile[], url: string, options?: IBulkUploadOptions): Observable<IStreamUploadResult<T>>;
}

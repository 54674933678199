export enum UI_BUTTON_TYPE {
  default = 'default',
  primary = 'primary',
  outline = 'outline',
  outlineHovered = 'outlineHovered',
  outlineWhite = 'outlineWhite',
  fab = 'fab',
  fabNaked = 'fabNaked',
  fabNakedPrimary = 'fabNakedPrimary',
  fabGray = 'fabGray',
  fabPrimary = 'fabPrimary',
  defaultGray = 'defaultGray',
  fabSquare = 'fabSquare',
}

export enum UI_BUTTON_SIZE {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

import {NgModule} from '@angular/core';
import {ThemeRootComponent} from './components/theme-root.component';
import {ImageGridSpacingService} from './services/image-grid-spacing.service';
import {ThemingColorsService} from './services/theming-colors.service';
import {ThemingCoversService} from './services/theming-covers.service';
import {ThemingTypographyService} from './services/theming-typography.service';

@NgModule({
  imports: [ThemeRootComponent],
  providers: [ImageGridSpacingService, ThemingTypographyService, ThemingCoversService, ThemingColorsService],
  exports: [ThemeRootComponent],
})
export class FeatureThemingModule {}

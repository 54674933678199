import {Injectable} from '@angular/core';
import {ImageFile} from '../models/image-file';

@Injectable()
export class ImageFileService {
  async getAspect(imageFile: ImageFile): Promise<number> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = (): void => {
        resolve(image.width / image.height);
      };
      image.onerror = (e): void => reject(e);
      image.src = imageFile.url;
    });
  }
}

import {CommonModule} from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  OnInit,
  Output,
} from '@angular/core';
import {MdsBannerComponent} from '@mds/components/banner';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {TranslateModule} from '@ngx-translate/core';
import {MdsButtonComponent} from '@pui/components/button';
import {PlatformEnvironment} from '@px/shared/env';
import {CheckUpdatesService} from '@px/update-notification/domain';
import {UPDATE_NOTIFICATION_FEATURE_NAME} from './consts/feature-name.const';

@UntilDestroy()
@Component({
  selector: 'px-update-notification-banner',
  templateUrl: './update-notification-banner.component.html',
  styleUrls: ['./update-notification-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TranslateModule, MdsButtonComponent, MdsBannerComponent],
})
export class UpdateNotificationBannerComponent implements OnInit {
  private readonly checkUpdatesService = inject(CheckUpdatesService);
  private readonly cdr = inject(ChangeDetectorRef);
  private readonly platform = inject(PlatformEnvironment);

  isShown = false;

  @Output() refresh$ = new EventEmitter<void>();

  ngOnInit(): void {
    if (!this.platform.hasFeature(UPDATE_NOTIFICATION_FEATURE_NAME)) {
      return;
    }

    this.checkUpdatesService.initializeCheck().pipe(untilDestroyed(this)).subscribe();
    this.checkUpdatesService.updateIsReady$.pipe(untilDestroyed(this)).subscribe(state => {
      this.isShown = state;
      this.cdr.detectChanges();
    });
  }

  refresh(): void {
    this.refresh$.emit();
  }
}

import {Injectable} from '@angular/core';
import {ThemingEntityBase} from './theming-entity-base.service';

@Injectable()
export class ThemingTypographyService extends ThemingEntityBase {
  protected initDefaultValues(): void {
    this.defaultPresetID = null;
    this.presets = {};
  }
}

import {CollectionDownloadType} from '../enums/collection-download-type.enum';
import {DownloadRequestSource} from '../enums/download-request-source.enum';

export interface IRequestCollectionContext {
  downloadPin?: string;
  requestSource?: DownloadRequestSource;
  agreeToTerms: boolean;
  downloadType: CollectionDownloadType;
  visitorEmail?: string;
}

export type IImageDownloadContext = Pick<IRequestCollectionContext, 'downloadPin'>;

export interface ICollectionArchiveContext extends Pick<IRequestCollectionContext, 'downloadPin' | 'downloadType'> {
  imageIds?: number[];
}

export type IRequestCollectionArchiveContext = IRequestCollectionContext & ICollectionArchiveContext;

export type IRequestCollectionArchivePublicContext = Omit<IRequestCollectionContext, 'visitorEmail'> &
  Required<Pick<IRequestCollectionContext, 'visitorEmail'>>;

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  booleanAttribute,
  inject,
} from '@angular/core';
import {UI_BUTTON_SIZE, UI_BUTTON_TYPE} from './ui-button.constants';

@Component({
  selector: 'px-button',
  templateUrl: './ui-button.component.html',
  styleUrls: ['./ui-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UiButtonComponent implements OnInit, AfterViewInit, OnChanges {
  private readonly cdr = inject(ChangeDetectorRef);

  @Input() type: keyof typeof UI_BUTTON_TYPE = UI_BUTTON_TYPE.default;
  @Input() size?: keyof typeof UI_BUTTON_SIZE;

  hostClass: string[] = [];
  isIcon = false;

  @Output() click$ = new EventEmitter<void>();

  @Input({transform: booleanAttribute}) disabled = false;
  @Input({transform: booleanAttribute}) hasIconLeft = false;
  @Input({transform: booleanAttribute}) hasIconRight = false;
  @Input({transform: booleanAttribute}) active = false;
  @Input({transform: booleanAttribute}) hasProgress = false;

  private initTransitionsClass(): void {
    this.hostClass.push('has-transitions');

    this.cdr.detectChanges();
  }

  private initHostClass(): void {
    switch (this.type) {
      case UI_BUTTON_TYPE.default:
        this.hostClass.push('c-button--primary', 'c-button--naked');
        break;
      case UI_BUTTON_TYPE.fab:
        this.hostClass.push('c-button--fab');
        this.isIcon = true;
        break;
      case UI_BUTTON_TYPE.fabNaked:
        this.hostClass.push('c-button--fab', '-naked');
        this.isIcon = true;
        break;
      case UI_BUTTON_TYPE.fabNakedPrimary:
        this.hostClass.push('c-button--fab', '-naked-primary');
        this.isIcon = true;
        break;
      case UI_BUTTON_TYPE.fabGray:
        this.hostClass.push('c-button--fab', '-gray');
        this.isIcon = true;
        break;
      case UI_BUTTON_TYPE.fabPrimary:
        this.hostClass.push('c-button--fab', '-primary');
        this.isIcon = true;
        break;

      case UI_BUTTON_TYPE.primary:
        this.hostClass.push('c-button--primary');
        break;
      case UI_BUTTON_TYPE.defaultGray:
        this.hostClass.push('c-button--primary', '-gray');
        break;

      case UI_BUTTON_TYPE.outline:
        this.hostClass.push('c-button--outline');
        break;
      case UI_BUTTON_TYPE.outlineHovered:
        this.hostClass.push('c-button--outline', '-hovered');
        break;
      case UI_BUTTON_TYPE.outlineWhite:
        this.hostClass.push('c-button--outline', '-white');
        break;

      case UI_BUTTON_TYPE.fabSquare:
        this.hostClass.push('c-button--fab', '-square');
        this.isIcon = true;
        break;
      default:
        break;
    }

    if (this.hasIconLeft || this.hasIconRight) {
      this.hostClass.push('c-button--icon');
    }

    if (this.size) {
      this.hostClass.push('-' + this.size);
    }

    if (this.hasProgress && this.type === UI_BUTTON_TYPE.primary) {
      this.hostClass.push('c-button--progress-bar');
    }

    this.cdr.markForCheck();
  }

  ngOnInit(): void {
    this.initHostClass();
  }

  ngAfterViewInit(): void {
    this.initTransitionsClass();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['hasProgress']) {
      this.initHostClass();
    }
  }
}

import {inject, Injectable} from '@angular/core';
import {filter, map, Observable} from 'rxjs';
import {BACKEND_CLIENT_ID} from '../tokens/backend-client-id';
import {IRegistrationContext} from '../models/registration-context';
import {IDeleteScopeContext} from '../models/delete-scope-context';
import {Apollo, gql} from 'apollo-angular';
import {plainToInstance} from 'class-transformer';
import {IVisitorSession} from '../entities/visitor-session.interface';
import {ISession, Session} from '@px/shared/session-provider';

interface IVisitorConfirmPayload {
  clientId: string;
  confirmationUrl: string;
  collectionId: string;
}

@Injectable()
export class EmailRegistrationService {
  private readonly apollo = inject(Apollo);
  private readonly clientId: string = inject(BACKEND_CLIENT_ID);

  private readonly registerVisitorMutation = gql<
    {registerVisitor: IVisitorSession},
    {clientId: string; email: string; registerContext: IRegistrationContext}
  >`
    mutation RegisterVisitor($clientId: String!, $email: String!, $registerContext: VisitorRegisterContext!) {
      registerVisitor(clientId: $clientId, email: $email, registerContext: $registerContext) {
        refreshId
        jwt
        expiration
      }
    }
  `;

  private readonly confirmVisitorMutation = gql<boolean, IVisitorConfirmPayload>`
    mutation ConfirmVisitor($clientId: String!, $confirmationUrl: String!, $collectionId: String!) {
      confirmVisitor(clientId: $clientId, confirmationUrl: $confirmationUrl, collectionId: $collectionId)
    }
  `;

  private readonly confirmCompleteVisitorMutation = gql<
    {confirmCompleteVisitor: IVisitorSession},
    {clientId: string; confirmationCode: string}
  >`
    mutation ConfirmCompleteVisitor($clientId: String!, $confirmationCode: String!) {
      confirmCompleteVisitor(clientId: $clientId, confirmationCode: $confirmationCode) {
        jwt
        refreshId
        expiration
      }
    }
  `;

  private readonly refreshVisitorMutation = gql<
    {refreshVisitor: IVisitorSession},
    {clientId: string; refreshId: string}
  >`
    mutation RefreshVisitor($clientId: String!, $refreshId: String!) {
      refreshVisitor(clientId: $clientId, refreshId: $refreshId) {
        jwt
        refreshId
        expiration
      }
    }
  `;

  private readonly deleteVisitorMutation = gql<{email: string}, {email: string; scope: IDeleteScopeContext}>`
    mutation DeleteVisitor($email: String!, $scope: VisitorDeleteScope) {
      deleteVisitor(email: $email, scope: $scope) {
        email
      }
    }
  `;

  deleteVisitor(email: string, scope: IDeleteScopeContext): Observable<boolean> {
    return this.apollo
      .mutate({
        mutation: this.deleteVisitorMutation,
        variables: {
          email,
          scope,
        },
        fetchPolicy: 'network-only',
        context: {
          canBeBatched: false,
        },
      })
      .pipe(
        filter(result => !!result.data),
        map(() => true)
      );
  }

  registerEmail(email: string, registerContext: IRegistrationContext): Observable<ISession> {
    return this.apollo
      .mutate({
        mutation: this.registerVisitorMutation,
        variables: {
          clientId: this.clientId,
          email,
          registerContext,
        },
        fetchPolicy: 'network-only',
        context: {
          canBeBatched: false,
        },
      })
      .pipe(
        filter(result => !!result.data),
        map(({data}) =>
          plainToInstance(Session, {
            token: data?.registerVisitor.jwt,
            refreshId: data?.registerVisitor.refreshId,
            expiration: data?.registerVisitor.expiration,
          })
        )
      );
  }

  registerConfirm(confirmationUrl: string, collectionId: string): Observable<boolean> {
    return this.apollo
      .mutate({
        mutation: this.confirmVisitorMutation,
        variables: {
          clientId: this.clientId,
          confirmationUrl,
          collectionId,
        },
        fetchPolicy: 'network-only',
        context: {
          canBeBatched: false,
        },
      })
      .pipe(map(result => !!result.data));
  }

  registerConfirmComplete(confirmationCode: string): Observable<ISession> {
    return this.apollo
      .mutate({
        mutation: this.confirmCompleteVisitorMutation,
        variables: {
          clientId: this.clientId,
          confirmationCode,
        },
        fetchPolicy: 'network-only',
        context: {
          canBeBatched: false,
        },
      })
      .pipe(
        filter(result => !!result.data),
        map(({data}) =>
          plainToInstance(Session, {
            token: data?.confirmCompleteVisitor.jwt,
            refreshId: data?.confirmCompleteVisitor.refreshId,
            expiration: data?.confirmCompleteVisitor.expiration,
          })
        )
      );
  }

  visitorRefresh(refreshId: string): Observable<ISession> {
    return this.apollo
      .mutate({
        mutation: this.refreshVisitorMutation,
        variables: {
          clientId: this.clientId,
          refreshId,
        },
        fetchPolicy: 'network-only',
        context: {
          canBeBatched: false,
        },
      })
      .pipe(
        filter(result => !!result.data),
        map(({data}) =>
          plainToInstance(Session, {
            token: data?.refreshVisitor.jwt,
            refreshId: data?.refreshVisitor.refreshId,
            expiration: data?.refreshVisitor.expiration,
          })
        )
      );
  }
}

import {ComponentRef, Injectable, Injector} from '@angular/core';
import {IUiSnackbarService} from '../interfaces/ui-snackbar-service.interface';
import {Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {UiSnackbarRef} from '../models/ui-snackbar-ref';
import {IUiSnackbarConfig} from '../interfaces/ui-snackbar-config.interface';
import {ComponentPortal, ComponentType} from '@angular/cdk/portal';
import {UiSnackbarDefaultComponent} from '../components/ui-snackbar/ui-snackbar-default.component';
import {UI_SNACKBAR_DATA} from '../tokens/ui-snackbar-data.token';
import {UiSnackbarTextComponent} from '../components/ui-snackbar-text/ui-snackbar-text.component';
import {UI_SNACKBAR_CONTAINER} from '../tokens/ui-container.token';
import {IUiSnackbarContainer} from '../interfaces/ui-snackbar-container.interface';
import {map, Observable, of, takeUntil, timer} from 'rxjs';
import {IUiSnackbarDataText} from '../interfaces/ui-snackbar-data-text.interface';

@Injectable()
export class UiSnackbarService implements IUiSnackbarService {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private snackbarRef?: UiSnackbarRef<any, any>;

  private DEFAULT_CONFIG = {
    hasBackdrop: false,
    backdropClass: '',
    duration: 3000,
  };

  constructor(
    private overlay: Overlay,
    private readonly injector: Injector
  ) {}

  private static attachContent<C>(
    component: ComponentType<C>,
    containerRef: ComponentRef<IUiSnackbarContainer<C>>
  ): void {
    const contentPortal = new ComponentPortal(component);
    containerRef.instance.attachPortal(contentPortal);
    containerRef.changeDetectorRef.detectChanges();
  }

  private createInjector<D, R, C>(config: IUiSnackbarConfig<D>, dialogRef: UiSnackbarRef<C, R>): Injector {
    return Injector.create(
      [
        {provide: UiSnackbarRef, useValue: dialogRef},
        {provide: UI_SNACKBAR_DATA, useValue: config?.data},
      ],
      this.injector
    );
  }

  private attachContainer<C, D, R>(
    snackbar: ComponentType<C>,
    config: IUiSnackbarConfig<D>,
    snackbarRef: UiSnackbarRef<C, R>
  ): IUiSnackbarContainer<C> {
    const injector = this.createInjector(config, snackbarRef);
    const containerPortal: ComponentPortal<IUiSnackbarContainer<C>> = new ComponentPortal(
      this.injector.get(UI_SNACKBAR_CONTAINER) ?? UiSnackbarDefaultComponent,
      null,
      injector
    );
    const containerRef = snackbarRef.overlayRef?.attach(containerPortal);
    snackbarRef.overlayRef.updatePosition();

    snackbarRef.containerRef = containerRef;

    UiSnackbarService.attachContent(snackbar, containerRef);

    return containerRef.instance;
  }

  private getOverlayConfig<D>(config: IUiSnackbarConfig<D>): OverlayConfig {
    const positionStrategy = this.overlay.position().global().bottom('24px').centerHorizontally();

    return new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      disposeOnNavigation: true,
      scrollStrategy: this.overlay.scrollStrategies.noop(),
      positionStrategy,
    });
  }

  private createOverlay<D>(config: IUiSnackbarConfig<D>): OverlayRef {
    const overlayConfig = this.getOverlayConfig(config);

    return this.overlay.create(overlayConfig);
  }

  open<C, D, R>(component: ComponentType<C>, config?: IUiSnackbarConfig<D>): Observable<UiSnackbarRef<C, R>> {
    const ref = this.snackbarRef;
    ref?.close();

    const dialogConfig = {...this.DEFAULT_CONFIG, ...config};
    const snackbarRef = new UiSnackbarRef<C, R>();
    this.snackbarRef = snackbarRef;

    const observable: Observable<void> = ref?.afterClosedInternal$.asObservable() ?? of(null);
    return observable.pipe(
      map(() => {
        snackbarRef.overlayRef = this.createOverlay(dialogConfig);
        if (snackbarRef.overlayRef.hostElement.parentElement) {
          snackbarRef.overlayRef.hostElement.parentElement.style.zIndex = String(10000000000);
        }

        this.attachContainer(component, dialogConfig, snackbarRef);

        if (!config?.persistent) {
          timer(config?.duration ?? this.DEFAULT_CONFIG.duration)
            .pipe(takeUntil(snackbarRef.afterClosedInternal$))
            .subscribe(() => {
              snackbarRef.close();
            });
        }

        return snackbarRef;
      })
    );
  }

  text<D extends IUiSnackbarDataText>(
    text: string,
    config?: IUiSnackbarConfig<D>
  ): Observable<UiSnackbarRef<UiSnackbarTextComponent, void>> {
    return this.open<UiSnackbarTextComponent, {text: string}, void>(UiSnackbarTextComponent, {
      ...config,
      data: {...(config?.data ?? {}), text},
    });
  }
}

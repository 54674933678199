import {GridSize} from '../enums/grid-size.enum';
import {MarginSize} from '../enums/margin-size.enum';

export interface IGridConfig extends IGridConfigBase {
  breakAt?: Record<number, IGridConfigBase>;
}

export interface IGridConfigBase {
  columns?: number;
  margin?: number;
}

export interface IGridParams {
  gridSize: GridSize;
  marginSize: MarginSize;
}

import {IContactInfo} from './contact-info.interface';

export interface IAccountInput {
  firstName: string;
  lastName: string;
  studioName: string;
  websiteUrl: string;
  email: string;
  password: string;
  password2: string;

  contactEmail: string;
  facebook: string;
  instagram: string;
  pinterest: string;
  tiktok: string;
  twitter: string;
  contactInfo: IContactInfo;
}
